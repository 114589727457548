import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Landing from "./pages/Landing";
import New from "./pages/userhome/New";
import pulse from "./img/pulse.svg";

// Login
import firebase, { auth, provider } from "./provider/firestore.js";
import "./provider/firestore.js";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    let currentComponent = this;

    auth.onAuthStateChanged((user) => {
      if (user) {
        const db = firebase.firestore();
        const userRef = db.collection("users").doc(user.uid);
        userRef.get().then(function (snapshot) {
          if (snapshot.exists) {
            let newUser = {
              role: snapshot.data().role,
              orgs: snapshot.data().orgs,
              email: snapshot.data().email,
              name: snapshot.data().name,
              photo: snapshot.data().photo,
              userID: snapshot.data().userID,
            };

            currentComponent.setState({
              user: newUser,
            });
          }
        });
      } else {
        currentComponent.setState({
          user: null,
        });
      }
    });
  }

  login() {
    let currentComponent = this;
    provider.setCustomParameters({ prompt: "select_account" });

    auth.signInWithPopup(provider).then((result) => {
      const user = result.user;
      //console.log(user)

      const db = firebase.firestore();
      const userRef = db.collection("users").doc(user.uid);
      userRef.get().then(function (snapshot) {
        if (snapshot.exists) {
          let newUser = {
            email: snapshot.data().email,
            role: snapshot.data().role,
            orgs: snapshot.data().orgs,
            displayName: snapshot.data().name,
            photo: snapshot.data().photo,
            userID: snapshot.data().userID,
          };

          currentComponent.setState({
            user: newUser,
          });
        } else {
          db.collection("users")
            .doc(user.uid)
            .set({
              name: user.displayName,
              photo: user.photo,
              orgs: [],
              email: user.email,
              userID: user.uid,
              role: "member",
            })
            .then(function (user) {
              //console.log("Document written with ID: ", user.uid);
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });

          currentComponent.setState({
            user: user,
          });
        }
      });
    });
  }

  logout() {
    let currentComponent = this;
    auth.signOut().then(() => {
      currentComponent.setState({
        user: null,
      });
    });
  }

  onUserChange(newUser) {
    this.setState({ user: newUser });
  }

  render() {
    return (
      <div>
        <div className="preloader">
          <img src={pulse} alt="spinner" className="pulse" />
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <Landing
                logout={this.logout}
                user={this.state.user}
                login={this.login}
                onUserChange={this.onUserChange}
              />
            }
          />
          <Route
            path="/about"
            element={<About logout={this.logout} user={this.state.user} />}
          />
          <Route
            path="/pcr/:id"
            element={<New logout={this.logout} user={this.state.user} />}
          />
        </Routes>
      </div>
    );
  }
}

export default App;
