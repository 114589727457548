import React from "react";
import { Link } from "react-router-dom";
import { NodeIndexOutlined } from "@ant-design/icons";
import defaultImage from "../img/defaultAvatar.jpeg";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

class HomeAppbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="profileImg">
          <a href="#">
            Change Profile Image
          </a>
        </Menu.Item>
        <Menu.Item key="logout" onClick={this.props.logout} >Log Out</Menu.Item>
      </Menu>
    );

    return (
      <div className="profile-link">
        <Dropdown overlay={menu} className="right-track" >
          <img src={this.props.user && this.props.user.photo ? this.props.user.photo : defaultImage} onClick={e => e.preventDefault()} />
        </Dropdown>
      </div>
    );
  }
}

export default HomeAppbar;
