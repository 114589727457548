import React from "react";
import { Link } from "react-router-dom";
import { NodeIndexOutlined, MoreOutlined } from "@ant-design/icons";
import report from "../../img/reports.svg";
import team from "../../img/team.svg";
import EmptyRecords from "../../components/EmptyRecords.js";

import { Card, Avatar, List, Row, Col, Select, Button, Menu, Dropdown } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Meta } = Card;
const { Option } = Select;

class Organizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {}

  handleRoleChange(value) {
    console.log(`selected ${value}`);
  }


  render() {
    const MenuOrg = (team) => {
      const teamId = team.id

      return (
      <Menu>
        <Menu.Item key={"viewmember"} onClick={() => this.props.changeTeam("members", team)}>
          View Members
        </Menu.Item>

        {teamId}
        <Menu.Item key={"delteorg"} danger onClick={() => this.props.deleteOrganization(teamId)}>Delete Organization</Menu.Item>
      </Menu>);
    };


    return (
      <div
        className={
          this.props.organizations.length > 0 ? "table-container" : "empty-container"
        }
      >
        {this.props.organizations.length > 0 ? (
          <div className="table-list--container">
            <Row>
              {this.props.organizations.map((item, key) => (
                <Col key={key} span={12}>
                  <Card
                    className="team-org--card"
                    cover={
                      <img
                        alt="example" 
                        onClick={() => this.props.changeTeam("members", item)}
                        src={team}
                      />
                    }
                  >
                    <List.Item className="all-teams--item">
                      <List.Item.Meta
                        title={item.name}
                      />
                      <Dropdown overlay={(
                        <Menu>
                          <Menu.Item key={"editTitle"} onClick={() => this.props.changeTeam("members", item)}>
                            Edit
                          </Menu.Item>
                          <Menu.Item key={"viewmember"} onClick={() => this.props.changeTeam("members", item)}>
                            View Members
                          </Menu.Item>
                          {item.admins.includes(this.props.user.userID) ? (
                            <Menu.Item key={"delteorg"} danger onClick={() => this.props.deleteOrganization(item.id)}>Delete Organization</Menu.Item>
                          ):("")}
                        </Menu>
                      )}>
                        <a className="ant-dropdown-link">
                          <Button type="text" icon={<MoreOutlined />} shape="circle" size="large"></Button>
                        </a>
                      </Dropdown>
                      
                    </List.Item>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <EmptyRecords
            image={report}
            title="Join an organization!"
            description={
              this.props.user.role == "admin"
                ? "Manage your team schedules, roles, and reports."
                : "Request access from your admin! View your team's schedules, roles, and reports."
            }
          />
        )}
      </div>
    );
  }
}

export default Organizations;
