import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import mike from "../img/mike.jpg";
import HomeAppbar from "../components/HomeAppbar.js";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="main-container">
        <div className="main-header">
          <HomeAppbar user={this.props.user} logout={this.props.logout} />
          <h1>Dashboard</h1>
        </div>
      </div>
    );
  }
}

export default Dashboard;
