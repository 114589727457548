import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";

import { Input, Button, Select, DatePicker, Tooltip } from "antd";

const { TextArea } = Input;

class NewSignatures extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "signatures",
        signatures: [],
      },
    };
  }

  componentDidMount() {}

  async addParticipant() {
    let uid =
      new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

    let newSig = [
      ...this.state.data.signatures,
      {
        id: uid,
        name: "",
        date: "",
        refusalBool: "Not refused",
        refusal: "",
      },
    ];
    const copyData = { ...this.state.data, signatures: newSig };
    this.setState({
      data: copyData,
    });
  }

  async removeSig(sigID) {
    let newSig = this.state.data.signatures.filter(function (si) {
      return si.id != sigID;
    });
    const copyData = { ...this.state.data, signatures: newSig };
    this.setState({
      data: copyData,
    });
  }

  handleChangeInput(sigID, type, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }

    let sig = this.state.data.signatures.filter(function (signature) {
      return signature.id == sigID;
    })[0];
    sig[type] = dt;

    const updatedSig = this.state.data.signatures.map((signature) => {
      return signature.id == sig.id ? sig : signature;
    });

    const newData = { ...this.state.data, signatures: updatedSig };
    this.setState({ data: newData });
  }

  clearInput(sigID, e) {
    let sig = this.state.data.signatures.filter(function (signature) {
      return signature.id == sigID;
    })[0];
    sig.sigPad.clear();

    const updatedSig = this.state.data.signatures.map((signature) => {
      return signature.id == sig.id ? sig : signature;
    });

    const newData = { ...this.state.data, signatures: updatedSig };
    this.setState({ data: newData });
  }

  advance() {
    console.log(this.state.data);
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Signatures</h1>
        <p>Everyone should sign</p>

        <div className="form--input-container">
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Participants<span>*</span>
              </h2>

              <button
                className="form--addon"
                onClick={() => this.addParticipant()}
              >
                + Add Participant
              </button>
            </div>
          </div>

          {this.state.data.signatures.map((sig, key) => (
            <div
              className="form--unit form--patient-record"
              key={key + "-select-sig"}
            >
              <div className="h-flex">
                <div
                  className="v-flex inputgroup"
                  style={{ marginRight: "3%" }}
                >
                  <Input
                    className="user-ant--input "
                    value={sig.name}
                    onChange={(e) => this.handleChangeInput(sig.id, "name", e)}
                    maxLength={185}
                  />
                  <Tooltip placement="bottomLeft" title="What is Name">
                    <span className="helper" style={{ marginLeft: "2%" }}>
                      Name
                    </span>
                  </Tooltip>
                </div>

                <div className="v-flex inputgroup">
                  <DatePicker
                    className="user-ant--input user--in"
                    placeholder=""
                    value={sig.date}
                    onChange={(e) => this.handleChangeInput(sig.id, "date", e)}
                  />
                  <Tooltip placement="bottomLeft" title="What is Date">
                    <span className="helper" style={{ marginLeft: "2%" }}>
                      Date
                    </span>
                  </Tooltip>
                </div>

                <div className="v-flex inputgroup" style={{ flexGrow: "1", marginLeft: "3%" }}>
                  <Select
                    className="user--in"
                    placeholder="Refusal"
                    allowClear
                    value={sig.refusalBool}
                    onChange={(e) =>
                      this.handleChangeInput(sig.id, "refusalBool", e)
                    }
                    options={[{ value: "Refused" }, { value: "Not refused" }].sort((a, b) => a.value.localeCompare(b.value))}
                  />
                  <Tooltip placement="bottomLeft" title="What is Refusal">
                    <span className="helper" style={{ marginLeft: "5%" }}>
                      Refusal
                    </span>
                  </Tooltip>
                </div>
              </div>

              {sig.refusalBool == "Not refused" ? (
                <div className="v-flex">
                  <div className="signature-wrapper">
                    <SignatureCanvas
                      className="canvasSign"
                      penColor="black"
                      clearOnResize={false}
                      ref={(ref) => {
                        sig.sigPad = ref;
                      }}
                      canvasProps={{}}
                    />
                  </div>

                  <div
                    className="h-flex"
                    style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
                  >
                    <Tooltip placement="bottomLeft" title="What is Signature">
                      <div
                        className="input-modal--label"
                        style={{ marginTop: "2em" }}
                      >
                        Sign Above:
                      </div>
                    </Tooltip>

                    <button
                      className="form--addon"
                      style={{ marginTop: "1em" }}
                      onClick={(e) => this.clearInput(sig.id, e)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form--unit">
                  <h2>Refusal Reason</h2>
                  <TextArea
                    className="textarea"
                    rows={4}
                    placeholder="Optional..."
                    value={sig.refusal}
                    onChange={(e) =>
                      this.handleChangeInput(sig.id, "refusal", e)
                    }
                    maxLength={700}
                  />
                </div>
              )}

              <div className="h-flex">
                <button
                  className="form--addon delete-form"
                  style={{ marginBottom: "1em" }}
                  onClick={() => this.removeSig(sig.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        {this.state.data.signatures.length > 0 ? (
          <button className="next-button" onClick={() => this.advance()}>
            Next
          </button>
        ) : (
          " "
        )}
      </div>
    );
  }
}

export default NewSignatures;
