import React from "react";
import { Link } from "react-router-dom";
import UserAppbar from "../../components/UserAppbar.js";
import Footer from "../../components/Footer.js";
import EmptyRecords from "../../components/EmptyRecords.js";
import contract from "../../img/contract.svg";

import {
  ShareAltOutlined,
  GlobalOutlined,
  SolutionOutlined,
  RocketOutlined,
} from "@ant-design/icons";

class UserHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

  }

  componentDidMount() {}



  render() {
      
    return (
      <div className="dash-main-container">
        <UserAppbar logout={this.props.logout} user={this.props.user}/>
        <div className="user-main--content">
            <div className="home-c">

                <div className="home-c--content">

                  <EmptyRecords image={contract} title="Your records are empty" description="You have no records at the moment. Please create a new PCR" />
                
                </div>

            </div>
            
            
        </div>
      </div>
    );
  }
}

export default UserHome;
