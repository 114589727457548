import { doc, getDoc, setDoc, getFirestore, collection, getDocs, updateDoc, deleteDoc, addDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from "../firestore.js";

const db = firebase.firestore();

export const setUser = async (user) => {
    await setDoc(doc(db, 'users', user.id), user);
};

export const createUser = async (user) => {
    await addDoc(collection(db, 'users'), user);
};

export const getUser = async (id) => {
    const userSnapshot = await getDoc(doc(db, 'users', id));
    if (userSnapshot.exists()) {
        return userSnapshot.data();
    } else {
        //console.log("User doesn't exist");
    }
};

export const getUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, "users"));
    const userList = usersSnapshot.docs.map((doc) => doc.data());
    return userList;
};

export const updateUser = async (user) => {
    const userRef = doc(db, "users", user.userID);
    await updateDoc(userRef, user);
};

export const addOrgUserGroup = async (userID, teamID) => {
    const userRef = doc(db, "users", userID);
    await updateDoc(userRef, {
        orgs: arrayUnion(teamID)
    });
};

export const removeOrgUserGroup = async (userID, teamID) => {
    const userRef = doc(db, "users", userID);
    await updateDoc(userRef, {
        orgs: arrayRemove(teamID)
    });
};


export const updateUserOrg = async (org, user) => {
    const userRef = doc(db, "users", user.userID);
    await updateDoc(userRef, {
        orgs: org
    });
};

export const deleteUser = async (user) => {
    const userRef = doc(db, "users", user.userID);
    await deleteDoc(userRef);
};
