import React from "react";
import { Link } from "react-router-dom";
import Appbar from "../components/Appbar.js";
import Footer from "../components/Footer.js";
import {
  ShareAltOutlined,
  GlobalOutlined,
  SolutionOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import Sidebar from "../components/Sidebar.js";
import Dashboard from "./Dashboard.js";
import Records from "./Records.js";
import Supply from "./Supply.js";
import Team from "./Team.js";


class Dash extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

  }

  componentDidMount() {}

  handleClick(currentTab) {
    this.setState({
        activeTab: currentTab
    })
  }


  renderSwitch(param) {
    switch(param) {
      case 0:
        return <Dashboard activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} />;
      case 1:
        return <Records activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} />;
      case 3:
        return <Team activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} />;
      case 2:
        return <Supply activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} />;
      default:
        return <Team activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} /> ;
    }
  }


  render() {
    return (
      <div className="dash-main-container">
        <Sidebar activeTab={this.state.activeTab} handleClick={this.handleClick.bind(this)} />
        <div className="dash-main--content">
            {this.renderSwitch(this.state.activeTab)}  
        </div>
      </div>
    );
  }
}

export default Dash;
