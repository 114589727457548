import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import {
  Input,
  Button,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Tooltip,
} from "antd";
const { TextArea } = Input;

class NewTreatment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "patient",
        treatment: [],
      },
    };
  }

  componentDidMount() {}

  async addMedication() {
    let uid =
      new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

    let newMedication = [
      ...this.state.data.treatment,
      {
        id: uid,
        caregiver: "",
        authorization: "",
        timeAdmin: "",
        medDosage: "",
        medDosageUnit: "",
        medDosageQuantity: "",
        adminRoute: "",
        narrative: "",
      },
    ];
    const copyData = { ...this.state.data, treatment: newMedication };
    this.setState({
      data: copyData,
    });
  }

  async removeTreatment(treatmentID) {
    let newTreatment = this.state.data.treatment.filter(function (treatment) {
      return treatment.id != treatmentID;
    });
    const copyData = { ...this.state.data, treatment: newTreatment };
    this.setState({
      data: copyData,
    });
  }

  handleChangeInput(tid, type, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }

    let treat = this.state.data.treatment.filter(function (treatment) {
      return treatment.id == tid;
    })[0];
    treat[type] = dt;

    const updatedTreatment = this.state.data.treatment.map((treatment) => {
      return treatment.id == treat.id ? treat : treatment;
    });

    const newData = { ...this.state.data, treatment: updatedTreatment };
    this.setState({ data: newData });
  }

  advance() {
    this.props.updateTab("6");
    console.log(this.state.data);
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Treatment</h1>
        <p>What did you give..?</p>
        <div className="form--input-container">
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Medicine<span>*</span>
              </h2>

              <button
                className="form--addon"
                onClick={() => this.addMedication()}
              >
                + New Medication
              </button>
            </div>

            {this.state.data.treatment.map((treatment, key) => (
              <div
                className="form--unit form--patient-record"
                key={key + "-select-treatment"}
              >
                <div className="h-flex">
                  <div
                    className="v-flex inputgroup"
                    style={{ marginRight: "2%" }}
                  >
                    <Input
                      className="user-ant--input "
                      value={treatment.caregiver}
                      onChange={(e) =>
                        this.handleChangeInput(treatment.id, "caregiver", e)
                      }
                      maxLength={185}
                    />
                    <Tooltip placement="bottomLeft" title="What is Caregiver">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Caregiver
                      </span>
                    </Tooltip>
                  </div>
                  <div className="v-flex inputgroup">
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{
                        flexGrow: "1",
                      }}
                      value={treatment.authorization}
                      onChange={(e) =>
                        this.handleChangeInput(treatment.id, "authorization", e)
                      }
                      options={[
                        { value: "Protocol (Standing Order)" },
                        { value: "Written Orders (Patient Specific)" },
                        { value: "On-Line (Remote Verbal Order)" },
                        { value: "On-Scene" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Authorization"
                    >
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Authorization
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="v-flex inputgroup">
                  <DatePicker
                    showTime
                    className="user-ant--input"
                    placeholder=""
                    value={treatment.timeAdmin}
                    style={{ marginTop: "1.5%", width: "100%", flexGrow: "1" }}
                    onChange={(e) =>
                      this.handleChangeInput(treatment.id, "timeAdmin", e)
                    }
                  />
                  <Tooltip
                    placement="bottomLeft"
                    title="What is Time administered"
                  >
                    <span className="helper">Time Administered</span>
                  </Tooltip>
                </div>

                <div className="h-flex" style={{ marginTop: "1.5%" }}>
                  <div className="v-flex inputgroup">
                    <AutoComplete
                      className="user-ant--input user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{ flexGrow: "1" }}
                      value={treatment.medication}
                      onChange={(e) =>
                        this.handleChangeInput(treatment.id, "medication", e)
                      }
                      options={[
                        { value: "Activated Charcoal" },
                        { value: "Albuterol" },
                        { value: "Aspirin" },
                        { value: "Epinephrine" },
                        { value: "Nitroglycerin" },
                        { value: "Oral Glucose Gel" },
                        { value: "Oxygen" },
                        { value: "Tylenol" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Medication">
                      <span className="helper">Medication</span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex" style={{ marginTop: "1.5%" }}>
                  <div className="v-flex inputgroup">
                    <Input
                      className="user-ant--input"
                      style={{ flexGrow: "1" }}
                      value={treatment.medDosage}
                      onChange={(e) =>
                        this.handleChangeInput(treatment.id, "medDosage", e)
                      }
                      maxLength={25}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Medication Dosage"
                    >
                      <span className="helper">Medication Dosage</span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginLeft: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      value={treatment.medDosageUnit}
                      onChange={(e) =>
                        this.handleChangeInput(treatment.id, "medDosageUnit", e)
                      }
                      options={[
                        { value: "Liters Per Minute (LPM)" },
                        { value: "Milligrams (mg)" },
                        { value: "Milliliters per Minute (ml/min)" },
                        { value: "Grams (gms)" },
                        { value: "Centimeters (cm)" },
                        { value: "Drops (gtts)" },
                        { value: "Micrograms per Hour (mcg/hr)" },
                        { value: "Grams per Hour (gms/hr)" },
                        { value: "Inches (in)" },
                        { value: "Keep Vein Open (kvo)" },
                        { value: "Liters (L)" },
                        { value: "Liters Per Minute (l/min [fluid])" },
                        { value: "Micrograms per Kilogram (mcg/kg)" },
                        {
                          value:
                            "Micrograms per Kilogram per Minute (mcg/kg/min)",
                        },
                        { value: "Micrograms per Minute (mcg/min)" },
                        { value: "Milliequivalents (mEq)" },
                        { value: "Milligrams per Hour (mg/hr)" },
                        { value: "Milligrams per Kilogram (mg/kg)" },
                        {
                          value:
                            "Micrograms per Kilogram Per Minute (mcg/kg/min)",
                        },
                        { value: "Milligrams per Minute (mg/min)" },
                        { value: "Milliliters (ml)" },
                        { value: "Milliliters per Hour (ml/hr)" },
                        { value: "Puffs" },
                        { value: "Units" },
                        { value: "Units per Hour (units/hr)" },
                        { value: "Units per Kilogram per Hour (units/kg/hr)" },
                        { value: "Units per Kilogram (units/kg)" },
                        { value: "Metered Dose (MDI)" },
                        { value: "Micrograms (mcg)" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Dosage Unit">
                      <span className="helper">Dosage Unit</span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup">
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{ flexGrow: "1" }}
                      value={treatment.medDosageQuantity}
                      onChange={(e) =>
                        this.handleChangeInput(
                          treatment.id,
                          "medDosageQuantity",
                          e
                        )
                      }
                      options={[
                        { value: "1" },
                        { value: "2" },
                        { value: "3" },
                        { value: "4" },
                        { value: "5" },
                        { value: "6" },
                        { value: "7" },
                        { value: "8" },
                        { value: "9" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Dosage Quality"
                    >
                      <span className="helper">Dosage Quality</span>
                    </Tooltip>
                  </div>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginBottom: "2em" }}
                >
                  <AutoComplete
                    className="user--in"
                    autoComplete="none"
                    filterOption={true}
                    style={{
                      marginTop: "1.5%",
                      marginBottom: "",
                      width: "100%",
                    }}
                    value={treatment.adminRoute}
                    onChange={(e) =>
                      this.handleChangeInput(treatment.id, "adminRoute", e)
                    }
                    options={[
                      { value: "Auto-Injector" },
                      { value: "Blow-By" },
                      { value: "Buccal" },
                      { value: "CPAP" },
                      { value: "Nebulizer" },
                      { value: "Bag Value Mask (BVM)" },
                      { value: "Metered Dose Inhaler" },
                      { value: "ET Tube" },
                      { value: "Gastrostomy Tube" },
                      { value: "Inhalation" },
                      { value: "Intraarterial" },
                      { value: "Intramuscular (IM)" },
                      { value: "Intranasal" },
                      { value: "Intraosseous (IO)" },
                      { value: "Intravenous (IV)" },
                      { value: "Nasal Cannula" },
                      { value: "Nasotracheal Tube" },
                      { value: "Ophthalmic" },
                      { value: "Oral" },
                      { value: "Nasogastric" },
                      { value: "Pump" },
                      { value: "Portacath" },
                      { value: "Rectal" },
                      { value: "Subcutaneous" },
                      { value: "Sublingual" },
                      { value: "Tracheostomy" },
                      { value: "Transdermal" },
                      { value: "Urethral" },
                    ].sort((a, b) => a.value.localeCompare(b.value))}
                  />

                  <Tooltip
                    placement="bottomLeft"
                    title="What is Administered Route"
                  >
                    <span className="helper">Administered Route</span>
                  </Tooltip>
                </div>

                <div className="v-flex inputgroup">
                  <TextArea
                    className="textarea"
                    value={treatment.narrative}
                    rows={4}
                    onChange={(e) =>
                      this.handleChangeInput(treatment.id, "narrative", e)
                    }
                    placeholder="Optional Narrative..."
                    maxLength={700}
                  />
                  <Tooltip placement="bottomLeft" title="What is Narrative">
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Narrative
                    </span>
                  </Tooltip>
                </div>

                <div className="h-flex">
                  <button
                    className="form--addon delete-form"
                    style={{ marginBottom: "1em" }}
                    onClick={() => this.removeTreatment(treatment.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {this.state.data.treatment.length > 0 ? (
          <button className="next-button" onClick={() => this.advance()}>
            Next
          </button>
        ) : (
          " "
        )}
      </div>
    );
  }
}

export default NewTreatment;
