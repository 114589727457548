import React from "react";
import { Link } from "react-router-dom";
import Appbar from "../components/Appbar.js";
import Footer from "../components/Footer.js";
import {
  ShareAltOutlined,
  GlobalOutlined,
  SolutionOutlined,
  RocketOutlined,
  CopyTwoTone,
  DatabaseTwoTone,
  ContactsTwoTone,
  LockTwoTone,
  IdcardTwoTone,
  CreditCardTwoTone,
} from "@ant-design/icons";

import mike from "../img/mike.jpg";
import alex from "../img/alex.jpeg";
import dorian from "../img/dorian.png";
import doctors from "../img/doctors.svg";
import medicine from "../img/medicine.svg";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="main-container">
        <Appbar login={this.props.login}/>
        <div className="main-content  light-grey">
          <div className="h-flex header-control">
            <div className="v-flex header-text">
              <h1>
                <div>We're building technology </div>{" "}
                <span>for emergency service providers</span>{" "}
              </h1>
              <p>Software suite for emergency services ​</p>
              <Link to="/">
                <button>Learn More</button>
              </Link>
            </div>
            <img src={medicine} className="pulse-img" />
          </div>

          <div className="site-section home-middle light-grey">
            <div className="h-flex landing-first">
              <div className="grid-item grid-mod grid-landing-message">
                <div className="v-flex">
                  <div className="v-flex img-content">
                    <div className="mold"><CopyTwoTone twoToneColor="#2596be" /></div>
                  </div>
                  <h3>Patient Care Reports</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin tincidunt, turpis.​
                  </p>
                </div>
              </div>
              <div className="grid-item grid-mod grid-landing-message">
                <div className="v-flex">
                  <div className="v-flex img-content">
                    <div className="mold"><DatabaseTwoTone twoToneColor="#2596be" /></div>
                  </div>
                  <h3>Supply Inventory</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin tincidunt, turpis.​
                  </p>
                </div>
              </div>

              <div className="grid-item grid-mod grid-landing-message">
                <div className="v-flex">
                  <div className="v-flex img-content">
                    <div className="mold"><ContactsTwoTone twoToneColor="#2596be" /></div>
                  </div>
                  <h3>Team Management​</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin tincidunt, turpis.​
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="site-section home-middle home-why white-grey-outline">
            <h2>Security at Pulse</h2>
            <div className="h-flex">
              <div className="grid-box about-why">
                <div className="grid-item grid-mod grid-security-item">
                  <div className="v-flex img-content">
                    <LockTwoTone twoToneColor="#2596be" />
                  </div>
                  <div className="v-flex">
                    <h3>Patient Information</h3>
                    <p>
                      Patient information is stored on Google Cloud Platform. The above Google services employ AES-256 encryption at rest and have completed ISO 27001, ISO 27017, ISO 27018, SOC 1, 2, and 3 certification.
                    </p>
                  </div>
                </div>
                <div className="grid-item grid-mod grid-security-item">
                  <div className="v-flex img-content">
                    <IdcardTwoTone twoToneColor="#2596be" />
                  </div>
                  <div className="v-flex">
                    <h3>Identity Management​</h3>
                    <p>
                      Patient identity data (name, email, password) are managed and stored in the Google Identity Platform. Google's Identity Platform implements industry-leading encryption (SHA-2) and authentication (OAuth 2.0) standards.
                    </p>
                  </div>
                </div>

                <div className="grid-item grid-mod grid-security-item">
                  <div className="v-flex img-content">
                    <CreditCardTwoTone twoToneColor="#2596be" />
                  </div>
                  <div className="v-flex">
                    <h3>Financial Information</h3>
                    <p>
                      All credit card, debit card, ACH, and other financial data is collected, stored, and processed via Stripe. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.
                    </p>
                  </div>
                </div>
                <div className="grid-item grid-mod grid-security-item">
                  <div className="v-flex img-content">
                    <SolutionOutlined twoToneColor="#2596be" />
                  </div>
                  <div className="v-flex">
                    <h3>Business Associate Agreements</h3>
                    <p>
                      Pulse has countersigned Business Associate's Agreements ("BAA") with Google certifying their compliance with all relevant HIPAA requirements vis-a-vis their data transmission and storage functions.  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
