import { doc, getDoc, setDoc, getFirestore, collection, getDocs, updateDoc, deleteDoc, addDoc, query, where, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from "../firestore.js";

const db = firebase.firestore();

export const createOrg = async (org) => {
    const orgRef = await addDoc(collection(db, "organization"), org);
    return orgRef
}

export const getOrg = async (id) => {
    const orgSnapshot = await getDoc(doc(db, 'organization', id));
    if (orgSnapshot.exists()) {
        let orgData = orgSnapshot.data()
        orgData.id = id
        return orgData;
    } else {
        return null
    }
};

export const getOrgs = async () => {
    const q = query(collection(db, "organization"), where("state", "==", "published"));
    const orgsSnapshot = await getDocs(q);
    const orgList = orgsSnapshot.docs.map((doc) => doc.data());
    return orgList
};

export const updateOrg = async (org) => {
    const orgRef = doc(db, "organization", org.id);
    await updateDoc(orgRef, org);
};

export const updateOrgMembers = async (org) => {
    const orgRef = doc(db, "organization", org.id);
    await updateDoc(orgRef, {
        members: org.members
    });
};

export const updateOrgMemberRole = async (teamID, admins) => {
    const userRef = doc(db, "organization", teamID);
    await updateDoc(userRef, {
        admins: admins,
    });
};

export const addOrgUser = async (userID, teamID) => {
    const userRef = doc(db, "organization", teamID);
    await updateDoc(userRef, {
        members: arrayUnion(userID)
    });
};

export const removeOrgUser = async (userID, teamID) => {
    const userRef = doc(db, "organization", teamID);
    await updateDoc(userRef, {
        admins: arrayRemove(userID),
        members: arrayRemove(userID)
    });
};

export const updateOrgStatus = async (org) => {
    const orgRef = doc(db, "organization", org.id);
    await updateDoc(orgRef, {
        state: org.state
    });
};

export const deleteOrg = async (org) => {
    const orgRef = doc(db, "organization", org.id);
    await deleteDoc(orgRef);
};
