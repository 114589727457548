import React from "react";
import { Link } from "react-router-dom";
import {ShareAltOutlined, GlobalOutlined, SolutionOutlined, RocketOutlined, HomeOutlined, FolderOpenOutlined, ToolOutlined, BankOutlined, TeamOutlined} from "@ant-design/icons";
import logo from "../img/pp.png";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {

  }

  render() {
    return <div className="sidebar">
        <Link to="/" className="appbar--logo"><span><img src={logo} /></span></Link>


        <div className={"sidebar--links" + (this.props.activeTab == 0 ? ' active' : '')} onClick={() => this.props.handleClick(0)}>
           <span><HomeOutlined /></span> Dashboard
        </div>
        <div className={"sidebar--links" + (this.props.activeTab == 1 ? ' active' : '')} onClick={() => this.props.handleClick(1)}>
           <span><FolderOpenOutlined /></span>Records
        </div>
        <div className={"sidebar--links" + (this.props.activeTab == 2 ? ' active' : '')} onClick={() => this.props.handleClick(2)}>
           <span><ToolOutlined /></span>Supply
        </div>
        <div className={"sidebar--links" + (this.props.activeTab == 3 ? ' active' : '')} onClick={() => this.props.handleClick(3)}>
           <span><TeamOutlined /></span> Team
        </div>
    </div>;
  }
}

export default Sidebar;
