import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import mike from "../img/mike.jpg";
import contract from "../img/contract.svg";
import { Menu } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  PlusOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import EmptyRecords from "../components/EmptyRecords.js";
import HomeAppbar from "../components/HomeAppbar.js";

class Records extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "records",
    };
  }

  componentDidMount() {}

  handleClick(currentTab) {
    this.setState({
        current: currentTab
    })
  }

  render() {
    return (
      <div className="main-container">
        <div className="main-header">
          <HomeAppbar user={this.props.user} logout={this.props.logout} />

          <h1>Records</h1>

          <Menu
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            mode="horizontal"
            style={{
                backgroundColor: "transparent",
                fontWeight: "500",
                color: "gray",
            }}
          >
            <Menu.Item key="records" onClick={() => this.handleClick("records")}>
             All Records
            </Menu.Item>
            <Menu.Item key="drafts" onClick={() => this.handleClick("drafts")}>
              Drafts
            </Menu.Item>
            <Menu.Item key="new" icon={<FolderOpenOutlined />} style={{marginLeft: "auto"}} onClick={() => this.handleClick("new")}>
               New Record
            </Menu.Item>
          </Menu>
        </div>

        <EmptyRecords image={contract} title="Oops... let's save more patients" description="You have no records at the moment" />

      </div>
    );
  }
}

export default Records;
