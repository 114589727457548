import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import {
  Input,
  Form,
  Radio,
  Button,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Modal,
  Tooltip,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

class NewScene extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "scene",
        responseUrgency: "",
        responseDelay: "",
        patientCount: "",
        transportDecision: "",
        narrative: "",
        agency: [],
        selectedAgency: [],
      },
      newAgency: {
        name: "",
        type: "",
      },
      modalMemberVisible: false,
    };
  }

  componentDidMount() {}

  setModalMemberVisible(visible) {
    this.setState({
      modalMemberVisible: visible,
    });
  }

  handleChangeNewAgency(type, event) {
    let dt = "";

    if (!["type"].includes(type)) {
      dt = event.target.value;
    } else {
      dt = event;
    }
    const copyState = { ...this.state.newAgency, [type]: dt };
    this.setState({ newAgency: copyState });
  }

  handleChangeInput(type, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }

    const newData = { ...this.state.data, [type]: dt };
    this.setState({ data: newData });
  }

  advance() {
    this.props.updateTab("3");
    console.log(this.state.data);
  }

  async addAgency() {
    let newAgency = [...this.state.data.agency, this.state.newAgency];
    const copyData = { ...this.state.data, agency: newAgency };
    const copyMember = {
      name: "",
      type: "",
    };

    this.setState({
      newAgency: copyMember,
      data: copyData,
      modalMemberVisible: false,
    });
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Scene</h1>
        <p>You’ll recieve this information en route and on scene. </p>

        <div className="form--unit">
          <h2>
            Response Information<span>*</span>
          </h2>

          <div className="h-flex">
            <div className="v-flex inputgroup">
              <AutoComplete
                filterOption={true}
                autoComplete="none"
                value={this.state.data.responseUrgency}
                onChange={(e) => this.handleChangeInput("responseUrgency", e)}
                options={[{ value: "Urgent" }, { value: "Non-urgent" }].sort((a, b) => a.value.localeCompare(b.value))}
              />
              <Tooltip placement="bottomLeft" title="What is response urgency?">
                <span className="helper" style={{ marginLeft: "1%" }}>
                  Response Urgency
                </span>
              </Tooltip>
            </div>
            <div className="v-flex inputgroup">
              <AutoComplete
                className="user--in"
                filterOption={true}
                autoComplete="none"
                style={{ marginLeft: "3%" }}
                value={this.state.data.responseDelay}
                onChange={(e) => this.handleChangeInput("responseDelay", e)}
                options={[{ value: "Accident" }, { value: "Traffic" }].sort((a, b) => a.value.localeCompare(b.value))}
              />
              <Tooltip placement="bottomLeft" title="What is response delay?">
                <span className="helper" style={{ marginLeft: "4%" }}>
                  Response Delay
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="location-grid">
          <div style={{ width: "47.3%" }}>
            <div className="h-flex">
              <h2>
                Other Agencies<span>*</span>
              </h2>
              <button
                className="form--addon"
                onClick={() => this.setModalMemberVisible(true)}
              >
                + Add Agencies
              </button>
            </div>

            <div className="v-flex inputgroup">
              <Select
                mode="multiple"
                className="user--in"
                onChange={(e) => this.handleChangeInput("selectedAgency", e)}
                allowClear
                style={{ flexGrow: "1", width: "100%" }}
                options={this.state.data.agency.map((agency) => ({
                  value: agency.name,
                })).sort((a, b) => a.value.localeCompare(b.value))}
              />
              <Tooltip placement="bottomLeft" title="What is agency?">
                <span className="helper" style={{ marginLeft: "1%" }}>
                  Agency
                </span>
              </Tooltip>
            </div>
          </div>

          <div className="margin-left" style={{ width: "49%" }}>
            <h2>
              Patients<span>*</span>
            </h2>

            <div className="h-flex">
              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input"
                  value={this.state.data.patientCount}
                  onChange={(e) => this.handleChangeInput("patientCount", e)}
                  maxLength={3}
                />
                <Tooltip placement="bottomLeft" title="What is patient count?">
                  <span className="helper" style={{ marginLeft: "2%" }}>
                    Patient Count
                  </span>
                </Tooltip>
              </div>

              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  filterOption={true}
                  autoComplete="none"
                  value={this.state.data.transportDecision}
                  onChange={(e) =>
                    this.handleChangeInput("transportDecision", e)
                  }
                  style={{ flexGrow: "1", marginLeft: "5%" }}
                  options={[
                    { value: "Transported" },
                    { value: "Refused transport" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip
                  placement="bottomLeft"
                  title="What is transport decision?"
                >
                  <span className="helper" style={{ marginLeft: "7%" }}>
                    Transport Decision
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <div className="form--unit">
          <h2>Narrative</h2>

          <TextArea
            className="textarea"
            rows={4}
            placeholder="Optional..."
            value={this.state.data.narrative}
            onChange={(e) => this.handleChangeInput("narrative", e)}
            maxLength={700}
          />
        </div>
        <button className="next-button" onClick={() => this.advance()}>
          Next
        </button>

        <Modal
          title="Add an Agency"
          centered
          visible={this.state.modalMemberVisible}
          onOk={() => this.setModalMemberVisible(false)}
          onCancel={() => this.setModalMemberVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalMemberVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.addAgency()}
            >
              Add
            </Button>,
          ]}
        >
          <div className="input-modal">
            <div className="h-flex">
              <div className="v-flex inputgroup" style={{ marginRight: "3%" }}>
                <Input
                  className="user-ant--input"
                  value={this.state.newAgency.name}
                  onChange={(e) => this.handleChangeNewAgency("name", e)}
                />

                <span className="helper" style={{ marginLeft: "3%" }}>
                  Agency Name
                </span>
              </div>

              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  filterOption={true}
                  autoComplete="none"
                  style={{ flexGrow: "1" }}
                  value={this.state.newAgency.type}
                  onChange={(e) => this.handleChangeNewAgency("type", e)}
                  options={[
                    { value: "Ambulance" },
                    { value: "Fire Fighters" },
                    { value: "Police" },
                    { value: "Police Department" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <span className="helper" style={{ marginLeft: "3%" }}>
                  Agency Type
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default NewScene;
