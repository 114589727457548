import React from "react";
import { Link } from "react-router-dom";
import { NodeIndexOutlined, MoreOutlined } from "@ant-design/icons";
import report from "../../img/reports.svg";
import EmptyRecords from "../../components/EmptyRecords.js";
import { List, Avatar, Button, Select } from "antd";
import {ShareAltOutlined, GlobalOutlined, SolutionOutlined, RocketOutlined, HomeOutlined, FolderOpenOutlined, ToolOutlined, BankOutlined, TeamOutlined} from "@ant-design/icons";

const { Option } = Select;

class AllMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {}

  getUserRole(user) {
    let userRole = "Member"

    if(this.props.selectedTeam.admins.includes(user.userID)){
      userRole = "Admin"
    }

    return userRole
  }

  render() {
    return (
      <div
        className={
          this.props.members.length > 0 ? "table-container" : "empty-container"
        }
      >
        {this.props.members.length > 0 ? (
          <div className="table-list--container">
            <h2 className="team-name">{this.props.selectedTeam.name}</h2>
            <List
              itemLayout="horizontal"
              dataSource={this.props.members}
              renderItem={(item) => (
                <List.Item className="all-teams--item">
                  <List.Item.Meta
                    avatar={<Avatar src={item.photo} />}
                    title={item.name}
                    description={item.email}
                  />

                  {item.userID == this.props.selectedTeam.owner ? (
                    <Select className="member-tags" defaultValue="owner" style={{ width: 130 }}>
                      <Option disabled value="owner">Owner</Option>
                    </Select>
                  ):(

                    this.props.selectedTeam.admins.includes(this.props.user.userID) ? (
                      <Select className="member-tags" defaultValue={this.getUserRole(item)} value={this.getUserRole(item)} style={{ width: 130 }} onChange={(value) => {this.props.handleRoleChange(item.userID, this.props.selectedTeam.id, value)}}>
                        <Option value="member">Member</Option>
                        <Option value="admin">Admin</Option>
                        <Option value="delete">Remove</Option>
                      </Select>
                    ):("")
                                    
                  )}

                </List.Item>
              )}
            />
          </div>
        ) : (
          <EmptyRecords
            image={report}
            title="Oops...add more friends"
            description={
              this.props.user.role == "admin"
                ? "Add members at your team, there's none at the moment"
                : "You have no members on your team at the moment!"
            }
          />
        )}
      </div>
    );
  }
}

export default AllMembers;
