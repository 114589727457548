import React from "react";
import { Link } from "react-router-dom";
import Appbar from "../components/Appbar.js";
import Footer from "../components/Footer.js";
import {
  ShareAltOutlined,
  GlobalOutlined,
  SolutionOutlined,
  RocketOutlined,
} from "@ant-design/icons";

import mike from "../img/mike.jpg"
import alex from "../img/alex.jpeg"
import dorian from "../img/dorian.png"

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="main-container">
        <Appbar />
        <div className="main-content">
          <div className="site-section home-middle">
            <div className="v-flex header-sec">
              <h1>About</h1>
              <p className="header-p">
                We're building technology for emergency service providers.
                ​
              </p>
            </div>
          </div>
          <div className="site-section home-middle light-grey">
            <h2>Why?</h2>
          </div>
          <div className="site-section home-middle home-about">
            <h2 className="meet-the">Meet the Team</h2>

            <div className="grid-box">
              <div className="grid-item team-item">
                <div className="v-flex iteml">
                  <img src={alex} className="teams-pic" />
                  <h3 className="teams-name">Alexandria Miera</h3>
                  <p>Electrical and Electronics Engineering student at Worcester Polytechnic Institute. 
                  Previously Team Lead at Lemelson MIT Project, Hanger Clinic where she shadowed prosethists during 
                  the devopment of prostethic devices. Currently Apart of Sandia National Laboratories. </p>
                </div>
              </div>
              <div className="grid-item team-item">
                <div className="v-flex iteml">
                  <img src={mike} className="teams-pic" />
                  <h3 className="teams-name">Michael Osei</h3>
                  <p>Computer Science & UX student at Worcester Polytechnic Institute.
                  Previously founded Augment, a music education company, worked at Dell Technologies on internal
                  tools, works as a grad assistant in the User Experience and Decision Making lab at WPI.  </p>
                </div>
              </div>

              <div className="grid-item team-item">
                <div className="v-flex iteml">
                  <img src={dorian} className="teams-pic" />
                  <h3 className="teams-name">Dorian Isidore</h3>
                  <p>Electrical and Computer Engineering Student at Worcester Polytechnic Institute. Previously 
                  worked on enviroment and sustainability usability in the La Plata, nature refuge project center in
                  central Puerto Rico. </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
