import React from "react";
import { Link } from "react-router-dom";
import { NodeIndexOutlined } from "@ant-design/icons";
import report from "../../img/reports.svg";
import EmptyRecords from "../../components/EmptyRecords.js";

class Schedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="empty-container">
        <EmptyRecords
          image={report}
          title="Your Schedule is Empty"
          description={this.props.user.role == "admin" ? "Create and manage your team's schedule" : "Your team's schedule is empty"}
        />
      </div>
    );
  }
}

export default Schedule;
