import React from "react";
import { Link } from "react-router-dom";
import {NodeIndexOutlined} from "@ant-design/icons";
import logo from "../img/pp.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };
  }

  componentDidMount() {

  }

  render() {
    return <div className="footer">
        <div className="v-flex">
            <Link to="/" className="appbar--logo"><span><img src={logo} /></span></Link>
            <br /><br /><br /><br /><br /><br /><br /><br />
            <div className="footer-copyright">Pulse © 2022. All Rights Reserved.</div>
        </div>

        <div className="v-flex footerlinks">
            
            <div className="h-flex">
                <div className="v-flex">
                    <h3 className="footerHeader">Contact</h3>
                    <Link to="/" className={"appbar--links-link" + (this.state.activeTab == 1 ? ' active' : '')}>
                        1-508-000-9000</Link>
                    <Link to="/" className={"appbar--links-link" + (this.state.activeTab == 0 ? ' active' : '') }> 
                        pulse@wpi.edu</Link>

                </div>

                <div className="v-flex">
                                    <h3 className="footerHeader">Opportunities</h3>

                    <Link to="/" className={"appbar--links-link" + (this.state.activeTab == 0 ? ' active' : '') }> 
                         100 Institute Road Worcester, MA 01609</Link>
                    <Link to="/" className={"appbar--links-link" + (this.state.activeTab == 0 ? ' active' : '') }> 
                        pulse@wpi.edu</Link>
                </div>

            </div>
        
        </div>

        
    </div>;
  }
}

export default Footer;
