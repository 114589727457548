import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import mike from "../img/mike.jpg";
import { Menu, Select } from "antd";

import { updateUserOrg, getUsers, addOrgUserGroup, removeOrgUserGroup } from "../provider/models/User.js";
import {
  setOrg,
  createOrg,
  getOrg,
  getOrgs,
  updateOrg,
  deleteOrg,
  updateOrgStatus,
  updateOrgMembers,
  addOrgUser,
  removeOrgUser,
  updateOrgMemberRole,
} from "../provider/models/OrgModel.js";

import { getUser } from "../provider/models/User.js";

import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  PlusOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import AllMembers from "./team/AllMembers.js";
import Organizations from "./team/Organizations.js";
import Schedule from "./team/Schedule.js";

import EmptyRecords from "../components/EmptyRecords.js";

import report from "../img/reports.svg";
import HomeAppbar from "../components/HomeAppbar.js";
import { Modal, Input, Button, Tag } from "antd";

const { SubMenu } = Menu;
const { Option } = Select;

class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "myorg",
      modalOrgVisible: false,
      modalMemberVisible: false,
      orgName: "",
      selectedTeam: null,
      modalSelectedTeam: null,
      members: [],
      allMembers: [],
      allModalMembers: [],
      organizations: [],
      selectedNewMembers: [],
    };

    this.changeTab = this.changeTab.bind(this);
    this.changeTeam = this.changeTeam.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
    this.handleChangeOrgTeam = this.handleChangeOrgTeam.bind(this);
    this.onChangeSelectPerson = this.onChangeSelectPerson.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
  }

  componentDidMount() {
    this.loadOrgs();
    this.loadAllUsers();
  }

  async loadAllUsers() {
    let currentState = this;
    let allUsers = await getUsers().then((users) => {
      currentState.setState({ allMembers: users });
    });
  }

  async loadOrgs() {
    let currentState = this;
    let yourOrgs = [];
    let oldOrgs = this.props.user.orgs;
    for (let i = 0; i < oldOrgs.length; i++) {
      if (oldOrgs.length > 0) {
        let org = await getOrg(oldOrgs[i]);
        if (org.state == "published") {
          this.fetchTeamMembers(org.members).then((membersRef) => {
            org.members = membersRef;
            yourOrgs.push(org);
            currentState.setState({ organizations: yourOrgs });
          });
        }
      }
    }
  }

  async fetchTeamMembers(teamMembers) {
    let yourMembers = [];
    for (let i = 0; i < teamMembers.length; i++) {
      if (teamMembers.length > 0) {
        let org = await getUser(teamMembers[i]);
        yourMembers.push(org);
      }
    }
    return yourMembers;
  }

  changeTab(currentTab) {
    this.setState({
      current: currentTab,
    });
  }

  changeTeam(currentTab, team) {
    this.setState({
      current: currentTab,
      selectedTeam: team,
      members: team.members,
    });

    //this.loadTeamMembers(team);
  }

  setModalMemberVisible(visible) {
    this.setState({
      modalMemberVisible: visible,
      modalSelectedTeam: null,
      selectedNewMembers: [],
    });
  }

  setModalOrgVisible(visible) {
    this.setState({
      modalOrgVisible: visible,
      orgName: "",
    });
  }

  onChangeOrgName = (e) => {
    this.setState({ orgName: e.target.value });
  };

  onChangeSelectPerson(selectedMembers) {
    this.setState({ selectedNewMembers: selectedMembers });
  }

  handleChangeOrgTeam(teamID) {
    let selectedOrg = this.state.organizations.filter(function (item) {
      return item.id == teamID;
    });
    let shortOrganization = selectedOrg[0].members.map(
      (userOrg) => userOrg.userID
    );
    let filteredNotMembers = this.state.allMembers.filter(function (member) {
      return !shortOrganization.includes(member.userID);
    });
    if (selectedOrg.length > 0) {
      this.setState({
        modalSelectedTeam: selectedOrg[0],
        allModalMembers: filteredNotMembers,
      });
    }
  }

  async deleteOrganization(id) {
    let currentState = this;

    // Update State
    let stateOrgs = this.state.organizations.filter(function (item) {
      return item.id !== id;
    });

    currentState.setState({
      organizations: stateOrgs,
    });

    let shortOrganization = stateOrgs.map((userOrg) => userOrg.id);
    let updatedUser = JSON.parse(JSON.stringify(currentState.props.user));
    updatedUser.orgs = shortOrganization;
    currentState.props.onUserChange(updatedUser);

    // Update Organization
    updateOrgStatus({ id: id, state: "deleted" }).then((orgRef) => {
      // Update User Organizations
      updateUserOrg(shortOrganization, currentState.props.user).then(
        (userRef) => {
          //console.log("Successful Update of User Document");
        }
      );
    });
  }

  async addMembers() {
    let currentState = this;
    let members = this.state.selectedNewMembers
    let team = this.state.modalSelectedTeam
    let teamId = team.id

    if(members.length > 0){
      for (let i = 0; i < members.length; i++) {
        let userId = members[i]
        let getUser = this.state.allMembers.filter(function (item) {
          return item.userID == userId;
        })[0];

        getUser.orgs.push(teamId)
        let newTeamMembers = team.members
        newTeamMembers.push(getUser)
        team.members = newTeamMembers

        // Organization
        let updatedTeamUserMap = newTeamMembers.map((userOrg) => userOrg.userID);
        //console.log(updatedTeamUserMap)

        // Update Backend 
        updateOrgMembers({
          id: teamId,
          members: updatedTeamUserMap
        }).then((orgRef) => {
          // Update User Organizations
          addOrgUserGroup(getUser.userID, teamId).then(
            (userRef) => {
              //console.log("Successful Update of User Document");
            }
          );
        });
        
      }
    }

    this.setState({
      modalSelectedTeam: null,
      selectedNewMembers: [],
      modalMemberVisible: false,
    });
  }

  async createOrganization() {
    let currentState = this;
    let tempID = new Date().getTime().toString(36);
    let newOrg = {
      id: tempID,
      name: this.state.orgName,
      members: [this.props.user],
      admins: [this.props.user.userID],
      state: "published",
      owner: this.props.user.userID,
    };

    let stateOrgs = [newOrg].concat(this.state.organizations);
    let orgId = await createOrg({
      name: this.state.orgName,
      owner: this.props.user.userID,
      state: "published",
      admins: [this.props.user.userID],
      members: [this.props.user.userID],
    }).then(async (orgRef) => {
      let orgID = orgRef.id;
      newOrg.id = orgID;

      currentState.setState({
        organizations: stateOrgs,
      });

      //console.log("Successful Creation of Organization Document");

      let shortOrganization = stateOrgs.map((userOrg) => userOrg.id);
      let updatedUser = JSON.parse(JSON.stringify(currentState.props.user));
      updatedUser.orgs = shortOrganization;

      // Update User Organization State
      currentState.props.onUserChange(updatedUser);

      // Update User Organizations
      updateUserOrg(shortOrganization, currentState.props.user).then(
        (userRef) => {
          //console.log("Successful Update of User Document");
        }
      );
    });

    this.setState({
      modalOrgVisible: false,
      organizations: stateOrgs,
      orgName: "",
    });
    this.changeTab("myorg");
  }

  arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
      return ele != value; 
    });
  }

  arrayMemberRemove(arr, value) { 
    return arr.filter(function(ele){ 
      return ele.userID != value; 
    });
  }

  handleRoleChange(userId, teamId, action) {
    let getTeam = this.state.organizations.filter(function (item) {
      return item.id == teamId;
    })[0];

    let getUser = this.state.allMembers.filter(function (item) {
      return item.userID == userId;
    })[0];

    let selectedOrg = this.state.organizations.filter(function (item) {
      return item.id == teamId;
    });

    // Make Admin
    if(action == "admin") {
        // Add User to Admin
        if (!this.state.selectedTeam.admins.includes(userId)){
          // Update Selected Teams
          let newTeamAdmins = this.state.selectedTeam.admins;
          newTeamAdmins.push(userId);
          let selectTeam = JSON.parse(JSON.stringify(this.state.selectedTeam));
          selectTeam.admins = newTeamAdmins;

          // Update Organizations
          let updatedOrganizations = this.state.organizations.map(obj => obj.id == selectTeam.id ? selectTeam :  obj);

          this.setState({
            selectedTeam: selectTeam,
            organizations: updatedOrganizations
          })
          
          // Update Backend
          updateOrgMemberRole(teamId, newTeamAdmins).then((userRef) => {});  
        } 
    }

    // Make Member
    if(action == "member") {
        // Remove Admin
        if (this.state.selectedTeam.admins.includes(userId)){
          // Update Selected Teams
          let newTeamAdmins = this.arrayRemove(this.state.selectedTeam.admins, userId);
          let selectTeam = JSON.parse(JSON.stringify(this.state.selectedTeam));
          selectTeam.admins = newTeamAdmins;

          // Update Organizations
          let updatedOrganizations = this.state.organizations.map(obj => obj.id == selectTeam.id ? selectTeam :  obj);

          this.setState({
            selectedTeam: selectTeam,
            organizations: updatedOrganizations
          })

          // Update Backend
          updateOrgMemberRole(teamId, newTeamAdmins).then((userRef) => {});   
        }
    }

    // Delete User
    if(action == "delete") {
        let getUserOrgs = this.arrayRemove(getUser.orgs, teamId);
        getUser.orgs = getUserOrgs

        let newTeamAdmins = this.arrayRemove(getTeam.admins, userId);
        getTeam.admins = newTeamAdmins

        let newTeamMembers = this.arrayMemberRemove(getTeam.members, userId);
        getTeam.members = newTeamMembers

        this.setState({
          members: newTeamMembers
        })

        // Update Backend
        removeOrgUser(userId, teamId).then((userRef) => {
          removeOrgUserGroup(userId, teamId).then((userRef) => {});
        });
    }
  }

  renderTeamViews(param) {
    switch (param) {
      case "members":
        return (
          <AllMembers
            user={this.props.user}
            selectedTeam={this.state.selectedTeam}
            members={this.state.members}
            onUserChange={this.props.onUserChange}
            handleRoleChange={this.handleRoleChange}
          />
        );
      case "myorg":
        return (
          <Organizations
            user={this.props.user}
            selectedTeam={this.state.selectedTeam}
            organizations={this.state.organizations}
            onUserChange={this.props.onUserChange}
            changeTab={this.changeTab}
            changeTeam={this.changeTeam}
            deleteOrganization={this.deleteOrganization}
          />
        );
      case "schedule":
        return (
          <Schedule
            user={this.props.user}
            onUserChange={this.props.onUserChange}
          />
        );
      default:
        return (
          <EmptyRecords
            user={this.props.user}
            onUserChange={this.props.onUserChange}
          />
        );
    }
  }

  render() {
    return (
      <div className="main-container">
        <div className="main-header">
          <HomeAppbar user={this.props.user} logout={this.props.logout} />

          <h1>Team</h1>
          <Menu
            selectedKeys={[this.state.current]}
            mode="horizontal"
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              color: "gray",
            }}
          >
            <Menu.Item key="myorg" onClick={() => this.changeTab("myorg")}>
              Organizations
            </Menu.Item>

            <Menu.Item
              key="schedule"
              onClick={() => this.changeTab("schedule")}
            >
              Schedule
            </Menu.Item>

            {this.props.user.role == "admin" ? (
              <SubMenu
                key="SubMenu"
                icon={<AppstoreOutlined />}
                title="Admin"
                style={{ marginLeft: "auto" }}
              >
                <Menu.ItemGroup title="Team">
                  <Menu.Item
                    key="setting:1"
                    onClick={() => this.setModalMemberVisible(true)}
                  >
                    Add Member
                  </Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="Organization">
                  <Menu.Item
                    key="setting:3"
                    onClick={() => this.setModalOrgVisible(true)}
                  >
                    New Organization
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            ) : (
              ""
            )}
          </Menu>
        </div>

        {this.renderTeamViews(this.state.current)}

        <Modal
          title="Create a New Organization"
          centered
          visible={this.state.modalOrgVisible}
          onOk={() => this.setModalOrgVisible(false)}
          onCancel={() => this.setModalOrgVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalOrgVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.createOrganization()}
            >
              Create
            </Button>,
          ]}
        >
          <div className="input-modal">
            <div className="input-modal--label">Organization Name</div>
            <Input
              onChange={this.onChangeOrgName}
              value={this.state.orgName}
              size="large"
              placeholder=""
              className="input-modal--input"
              prefix={<BankOutlined />}
            />
          </div>
          <div className="input-modal">
            <div className="input-modal--label">Owner</div>
            <Input
              size="large"
              value={this.props.user.name}
              placeholder=""
              className="input-modal--input"
              prefix={<UserOutlined />}
              disabled
            />
          </div>
        </Modal>

        <Modal
          title="Add a New Member"
          centered
          visible={this.state.modalMemberVisible}
          onOk={() => this.setModalMemberVisible(false)}
          onCancel={() => this.setModalMemberVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalMemberVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.addMembers()}
            >
              Add
            </Button>,
          ]}
        >
          <div className="input-modal member">
            <div className="input-modal--label">Select Organization</div>
            <Select
              className="input-modal--input"
              style={{ width: "100%" }}
              value={
                this.state.modalSelectedTeam
                  ? this.state.modalSelectedTeam.name
                  : ""
              }
              onChange={this.handleChangeOrgTeam}
            >
              {this.state.organizations.map((team, key) => (
                <Option key={key + "-select-users"} value={team.id}>
                  {team.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="input-modal">
            <div className="input-modal--label">Find Member</div>
            <Select
              mode="multiple"
              className="input-modal--input"
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              value={this.state.selectedNewMembers}
              onChange={this.onChangeSelectPerson}
            >
              {this.state.modalSelectedTeam
                ? this.state.allModalMembers.map((member, key) => (
                    <Option key={member.userID} value={member.userID}>
                      {member.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Team;
