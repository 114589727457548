import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Tooltip,
  Modal,
} from "antd";
const { TextArea } = Input;

class NewPatient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "patient",
        patients: [],
      },
      extraAtrributes: [],
      newAttribute: "",
    };
  }

  componentDidMount() {}

  async addPatient() {
    let uid =
      new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

    let newPatient = [
      ...this.state.data.patients,
      {
        id: uid,
        fName: "",
        mName: "",
        lName: "",
        dob: "",
        height: "",
        weight: "",
        race: "",
        sex: "",
        loi: "",
        signs: [],
        symptoms: [],
        allergies: [],
        medications: [],
        history: "",
        leadingEvents: "",
        homeAddress: "",
        socOne: "",
        socTwo: "",
        socThree: "",
      },
    ];
    const copyData = { ...this.state.data, patients: newPatient };
    this.setState({
      data: copyData,
    });
  }

  async removePatient(patientID) {
    let newPatients = this.state.data.patients.filter(function (patient) {
      return patient.id != patientID;
    });
    const copyData = { ...this.state.data, patients: newPatients };
    this.setState({
      data: copyData,
    });
  }

  handleChangeInput(pid, type, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }

    let patient = this.state.data.patients.filter(function (pat) {
      return pat.id == pid;
    })[0];
    patient[type] = dt;

    const updatedPatients = this.state.data.patients.map((pat) => {
      return pat.id == patient.id ? patient : pat;
    });

    const newData = { ...this.state.data, patients: updatedPatients };
    this.setState({ data: newData });
  }


  async addAttribute() {
    const att = this.state.newAttribute;
    const newAttributeArr = [...this.state.extraAtrributes, att];

    console.log(newAttributeArr);
    this.setState({
      extraAtrributes: newAttributeArr,
      newAttribute: "",
      modalAttributeVisible: false,
    });
  }

  handleChangeAttribute(e) {
    this.setState({ newAttribute: e.target.value });
  }

  advance() {
    this.props.updateTab("4");
    console.log(this.state.data);
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Patient Information</h1>
        <p>If there was patient contact add a patient record.</p>
        <div className="form--input-container">
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Patient Records<span>*</span>
              </h2>

              <button className="form--addon" onClick={() => this.addPatient()}>
                + Add Patients
              </button>
            </div>
          </div>

          {this.state.data.patients.map((patient, key) => (
            <div
              className="form--unit form--patient-record"
              key={key + "-select-patients"}
            >
              <div className="form--unit">
                <h2>
                  Demographics<span>*</span>
                </h2>

                <div className="h-flex">
                  <div className="v-flex inputgroup">
                    <Input
                      className="user-ant--input"
                      style={{ flexGrow: "1" }}
                      value={patient.fName}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "fName", e)
                      }
                      maxLength={35}
                    />
                    <Tooltip placement="bottomLeft" title="What is first name?">
                      <span className="helper" style={{ marginLeft: "3%" }}>
                        First Name
                      </span>
                    </Tooltip>
                  </div>
                  <div
                    className="v-flex inputgroup"
                    style={{ marginLeft: "3%", marginRight: "3%" }}
                  >
                    <Input
                      className="user-ant--input"
                      value={patient.mName}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "mName", e)
                      }
                      maxLength={25}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is middle name?"
                    >
                      <span className="helper" style={{ marginLeft: "3%" }}>
                        Middle Name
                      </span>
                    </Tooltip>
                  </div>
                  <div className="v-flex inputgroup">
                    <Input
                      className="user-ant--input"
                      style={{ flexGrow: "1" }}
                      value={patient.lName}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "lName", e)
                      }
                      maxLength={35}
                    />
                    <Tooltip placement="bottomLeft" title="What is last name?">
                      <span className="helper" style={{ marginLeft: "3%" }}>
                        Last Name
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex" style={{ marginTop: "1.5%" }}>
                  <div className="v-flex inputgroup">
                    <DatePicker
                      className="user-ant--input"
                      value={patient.dob}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "dob", e)
                      }
                      placeholder=""
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is date of birth?"
                    >
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Date of Birth
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginLeft: "3%" }}
                  >
                    <AutoComplete
                      className="user-ant--input"
                      filterOption={true}
                      autoComplete="none"
                      value={patient.height}
                      options={[
                        { value: "50in" },
                        { value: "51in" },
                        { value: "52in" },
                        { value: "53in" },
                        { value: "54in" },
                        { value: "55in" },
                        { value: "56in" },
                        { value: "57in" },
                        { value: "58in" },
                        { value: "59in" },
                        { value: "60in" },
                        { value: "61in" },
                        { value: "62in" },
                        { value: "63in" },
                        { value: "64in" },
                        { value: "65in" },
                        { value: "66in" },
                        { value: "67in" },
                        { value: "68in" },
                        { value: "69in" },
                        { value: "70in" },
                        { value: "71in" },
                        { value: "72in" },
                        { value: "73in" },
                        { value: "74in" },
                        { value: "75in" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "height", e)
                      }
                      maxLength={15}
                    />
                    <Tooltip placement="bottomLeft" title="What is height?">
                      <span className="helper" style={{ marginLeft: "3%" }}>
                        Height
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginLeft: "3%" }}
                  >
                    <AutoComplete
                      className="user-ant--input"
                      filterOption={true}
                      autoComplete="none"
                      value={patient.weight}
                      options={[
                        { value: "90lb" },
                        { value: "100lb" },
                        { value: "110lb" },
                        { value: "120lb" },
                        { value: "130lb" },
                        { value: "140lb" },
                        { value: "150lb" },
                        { value: "160lb" },
                        { value: "170lb" },
                        { value: "180lb" },
                        { value: "190lb" },
                        { value: "200lb" },
                        { value: "210lb" },
                        { value: "220lb" },
                        { value: "230lb" },
                        { value: "240lb" },
                        { value: "250lb" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "weight", e)
                      }
                      maxLength={15}
                    />
                    <Tooltip placement="bottomLeft" title="What is weight?">
                      <span className="helper" style={{ marginLeft: "3%" }}>
                        Weight
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex">
                  <div className="v-flex inputgroup">
                    <AutoComplete
                      className="user--in"
                      filterOption={true}
                      autoComplete="none"
                      style={{
                        marginTop: "1.5%",
                        marginRight: "3%",
                        flexGrow: "1",
                      }}
                      value={patient.race}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "race", e)
                      }
                      options={[
                        { value: "Black or African American" },
                        { value: "Asian" },
                        { value: "Hispanic or Latino or Spanish Origin" },
                        { value: "White" },
                        { value: "American Indian or Alaska Native" },
                        { value: "Native Hawaiian or Other Pacific Islander" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is race?">
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Race
                      </span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup">
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{ marginTop: "1.5%", flexGrow: "1" }}
                      value={patient.sex}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "sex", e)
                      }
                      options={[{ value: "Female" }, { value: "Male" }].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Sex Assignment?"
                    >
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Sex Assignment
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="form--unit">
                <div className="h-flex">
                  <h2>
                    History<span>*</span>
                  </h2>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginBottom: "1.5%", width: "100%" }}
                >
                  <DatePicker
                    className="user-ant--input "
                    placeholder=""
                    value={patient.loi}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "loi", e)
                    }
                  />
                  <Tooltip
                    placement="bottomLeft"
                    title="What is Last Oral Intake?"
                  >
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Last Oral Intake
                    </span>
                  </Tooltip>
                </div>

                <div className="v-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user--in"
                      mode="tags"
                      tokenSeparators={[',']}
                      allowClear
                      value={patient.signs}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "signs", e)
                      }
                      options={[
                        { value: "Skin is Blue" },
                        { value: "Rapid breathing" },
                        { value: "Slurred Speech" },
                      ].concat(
                        this.state.extraAtrributes.map((attr) => ({
                          value: attr,
                        }))
                      ).sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Signs?">
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Signs
                      </span>
                    </Tooltip>
                  </div>
                  <div className="v-flex inputgroup" style={{ flexGrow: "1"}}>
                    <Select
                      className="user--in"
                      mode="tags"
                      tokenSeparators={[',']}
                      allowClear
                      value={patient.symptoms}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "symptoms", e)
                      }
                      options={[
                        { value: "Bleeding" },
                        { value: "Breathing problems" },
                        { value: "Change in mental status" },
                        { value: "Chest Pain" },
                        { value: "Choking" },
                        { value: "Coughing or Vomiting Blood" },
                        { value: "Fainting" },
                        { value: "Suicidal" },
                        { value: "Abdominal Pain" },
                        { value: "Dizziness" },
                      ].concat(
                        this.state.extraAtrributes.map((attr) => ({
                          value: attr,
                        }))
                      ).sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Symptoms?">
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Symptoms
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="v-flex" style={{ marginTop: "1.5%",  flexGrow: "1" }}>
                  <div className="v-flex inputgroup">
                    <Select
                      className="user--in"
                      mode="tags"
                      tokenSeparators={[',']}
                      allowClear
                      value={patient.allergies}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "allergies", e)
                      }
                      options={[
                        { value: "Drug" },
                        { value: "Food" },
                        { value: "Insect" },
                        { value: "Latex" },
                        { value: "Mold" },
                        { value: "Pet" },
                        { value: "Pollen" },
                      ].concat(
                        this.state.extraAtrributes.map((attr) => ({
                          value: attr,
                        }))
                      ).sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Allergies?">
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Allergies
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1" }}
                  >
                    <Select
                      className="user--in"
                      mode="tags"
                      tokenSeparators={[',']}
                      allowClear
                      value={patient.medications}
                      onChange={(e) =>
                        this.handleChangeInput(patient.id, "medications", e)
                      }
                      options={[
                        { value: "Activated Charcoal" },
                        { value: "Albuterol" },
                        { value: "Aspirin" },
                        { value: "Epinephrine" },
                        { value: "Nitroglycerin" },
                        { value: "Oral Glucose Gel" },
                        { value: "Oxygen" },
                        { value: "Tylenol" },
                      ].concat(
                        this.state.extraAtrributes.map((attr) => ({
                          value: attr,
                        }))
                      ).sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Medications?"
                    >
                      <span className="helper" style={{ marginLeft: "1%" }}>
                        Medications
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="v-flex inputgroup">
                  <TextArea
                    className="textarea"
                    style={{ marginTop: "1.5%" }}
                    value={patient.history}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "history", e)
                    }
                    rows={4}
                    maxLength={700}
                  />
                  <Tooltip
                    placement="bottomLeft"
                    title="What is Pertinent Medical History?"
                  >
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Pertinent Medical History
                    </span>
                  </Tooltip>
                </div>
                <div className="v-flex inputgroup">
                  <TextArea
                    className="textarea"
                    value={patient.leadingEvents}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "leadingEvents", e)
                    }
                    style={{ marginTop: "1.5%" }}
                    rows={4}
                    maxLength={700}
                  />
                  <Tooltip
                    placement="bottomLeft"
                    title="What is Events leading up to call?"
                  >
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Events leading up to call
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="form--unit">
                <h2>
                  Billing<span>*</span>
                </h2>

                <div className="v-flex inputgroup">
                  <Input
                    className="user-ant--input"
                    style={{ flexGrow: "1" }}
                    value={patient.homeAddress}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "homeAddress", e)
                    }
                    maxLength={153}
                  />
                  <Tooltip placement="bottomLeft" title="What is Home Address?">
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Home Address
                    </span>
                  </Tooltip>
                </div>

                <div
                  className="input-modal--label"
                  style={{ marginTop: "3%", color: "gray" }}
                >
                  Social Security
                </div>

                <div className="h-flex" style={{ marginTop: "-0.2%" }}>
                  <Input
                    className="user-ant--input user--in"
                    style={{ flexGrow: "1" }}
                    placeholder="000"
                    value={patient.socOne}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "socOne", e)
                    }
                    maxLength={3}
                  />
                  <div className="ss--divide">-</div>
                  <Input
                    className="user-ant--input user--in"
                    style={{ flexGrow: "1" }}
                    placeholder="00"
                    value={patient.socTwo}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "socTwo", e)
                    }
                    maxLength={2}
                  />
                  <div className="ss--divide">-</div>
                  <Input
                    className="user-ant--input user--in"
                    style={{ flexGrow: "1" }}
                    placeholder="0000"
                    value={patient.socThree}
                    onChange={(e) =>
                      this.handleChangeInput(patient.id, "socThree", e)
                    }
                    maxLength={4}
                  />
                </div>

                <div className="h-flex">
                  <button
                    className="form--addon delete-form"
                    onClick={() => this.removePatient(patient.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {this.state.data.patients.length > 0 ? (
          <button className="next-button" onClick={() => this.advance()}>
            Next
          </button>
        ) : (
          " "
        )}

      </div>
    );
  }
}

export default NewPatient;
