import React from "react";
import Home from "./Home.js";
import Dash from "./Dash.js";
import UserHome from "./userhome/UserHome.js";

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {}

  render() {
    return (
        this.props.user ? <UserHome logout={this.props.logout} user={this.props.user} onUserChange={this.props.onUserChange} /> : <Home login={this.props.login} user={this.props.user} />
    );
  }
}

export default Landing;
