import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled, UserOutlined } from "@ant-design/icons";
import {
  Input,
  Button,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Tooltip,
  Modal,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

class NewDispatch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalMemberVisible: false,
      modalLocationVisible: false,
      data: {
        id: "dispatch",
        unit: {
          basesite: "",
          unitNumber: "",
          unitType: "",
        },
        crew: [],
        selectedCrew: [],
        pickup: {
          location: null,
          mode: "",
          unitType: "",
        },
        destination: {
          location: null,
          mode: "",
          unitType: "",
          caregiverName: "",
          caregiverRole: "",
        },
        locations: [
          { name: "Faraday Hall, WPI" },
          { name: "East Hall, WPI" },
          { name: "Institute Hall, WPI" },
          { name: "Boston Memorial" },
        ],
      },
      newCrewMember: {
        name: "",
        id: "",
        level: "",
        role: "",
      },
      newLocation: {
        name: "",
        address: "",
        lat: "",
        long: "",
      },
    };
  }

  componentDidMount() {}

  // Modal Data Binding
  setModalMemberVisible(visible) {
    this.setState({
      modalMemberVisible: visible,
    });
  }

  handleChangeNewCrewMember(type, event) {
    let dt = "";

    if (!["level", "role"].includes(type)) {
      dt = event.target.value;
    } else {
      dt = event;
    }
    const copyState = { ...this.state.newCrewMember, [type]: dt };
    this.setState({ newCrewMember: copyState });
  }

  handleChangeCrew(e) {
    const copyData = { ...this.state.data, selectedCrew: e };
    this.setState({ data: copyData });
  }

  async addMembers() {
    let newCrew = [...this.state.data.crew, this.state.newCrewMember];
    const copyData = { ...this.state.data, crew: newCrew };
    const copyMember = {
      name: "",
      id: "",
      level: "",
      role: "",
    };

    this.setState({
      newCrewMember: copyMember,
      data: copyData,
      modalMemberVisible: false,
    });
  }

  // Location

  setModalLocationVisible(visible) {
    this.setState({
      modalLocationVisible: visible,
    });
  }

  async addLocations() {
    let newLocation = [...this.state.data.locations, this.state.newLocation];
    const copyData = { ...this.state.data, locations: newLocation };
    const copyLocation = {
      name: "",
      address: "",
      lat: "",
      long: "",
    };

    this.setState({
      newLocation: copyLocation,
      data: copyData,
      modalLocationVisible: false,
    });
  }

  handleChangeNewLocation(type, event) {
    let dt = event.target.value;
    const copyState = { ...this.state.newLocation, [type]: dt };
    this.setState({ newLocation: copyState });
  }

  // Input Data Binding
  handleChangeInput(section, type, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }
    const copySection = { ...this.state.data[section], [type]: dt };
    const copyData = { ...this.state.data, [section]: copySection };

    this.setState({ data: copyData });
  }

  advance() {
    this.props.updateTab("2");
    console.log(this.state.data);
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Dispatch</h1>
        <p>
          You’ll receive some of this information at the time the call is
          dispatched.
        </p>

        <div className="form--input-container">
          <div className="form--unit">
            <h2>
              Unit Information<span>*</span>
            </h2>

            <div className="h-flex">
              <div className="v-flex inputgroup" style={{ width: "50%" }}>
                <AutoComplete
                  value={this.state.data.unit.basesite}
                  autoComplete="none"
                  onChange={(e) =>
                    this.handleChangeInput("unit", "basesite", e)
                  }
                  filterOption={true}
                  options={this.state.data.locations.map((locale) => ({
                    value: locale.name,
                  })).sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is Base Site?">
                  <span className="helper">Base Site</span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup" style={{ marginLeft: "1%" }}>
                <Input
                  className="user-ant--input unin"
                  value={this.state.data.unit.unitNumber}
                  onChange={(e) =>
                    this.handleChangeInput("unit", "unitNumber", e)
                  }
                  maxLength={5}
                />
                <Tooltip placement="bottomLeft" title="What is Unit Number?">
                  <span className="helper" style={{ marginLeft: "4%" }}>
                    Unit Number
                  </span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup" style={{ marginLeft: "2%" }}>
                <AutoComplete
                  className="user--in"
                  value={this.state.data.unit.unitType}
                  autoComplete="none"
                  filterOption={true}
                  onChange={(e) =>
                    this.handleChangeInput("unit", "unitType", e)
                  }
                  options={[{ value: "BLS" }, { value: "ALS" }].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is Unit Type?">
                  <span className="helper" style={{ marginLeft: "3%" }}>
                    Unit Type
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Crew Information<span>*</span>
              </h2>
              <button
                className="form--addon"
                onClick={() => this.setModalMemberVisible(true)}
              >
                + Add Members
              </button>
            </div>
            <div className="v-flex inputgroup">
              <Select
                mode="multiple"
                allowClear
                value={this.state.data.selectedCrew}
                onChange={(e) => this.handleChangeCrew(e)}
              >
                {this.state.data.crew.map((member, key) => (
                  <Option
                    className="options-cell"
                    key={key + "-select-users"}
                    value={member.id}
                  >
                    {member.name}
                  </Option>
                ))}
              </Select>
              <Tooltip placement="bottomLeft" title="Who are crew members?">
                <span className="helper">Add Crew Members</span>
              </Tooltip>
            </div>
          </div>
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Pick Up Location<span>*</span>
              </h2>
              <button
                className="form--addon"
                onClick={() => this.setModalLocationVisible(true)}
              >
                + Add Location
              </button>
            </div>

            <div className="h-flex">
              <div className="v-flex inputgroup">
                <AutoComplete
                  value={this.state.data.pickup.location}
                  autoComplete="none"
                  onChange={(e) =>
                    this.handleChangeInput("pickup", "location", e)
                  }
                  autoComplete="none"
                  filterOption={true}
                  options={this.state.data.locations.map((locale) => ({
                    value: locale.name,
                  })).sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is location?">
                  <span className="helper" style={{ marginLeft: "2%" }}>
                    Location
                  </span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  value={this.state.data.pickup.mode}
                  autoComplete="none"
                  onChange={(e) => this.handleChangeInput("pickup", "mode", e)}
                  style={{ flexGrow: "1", marginLeft: "3%" }}
                  filterOption={true}
                  options={[
                    { value: "Lights On" },
                    { value: "Sirens On" },
                    { value: "Lights Off" },
                    { value: "Sirens Off" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is mode?">
                  <span className="helper" style={{ marginLeft: "4%" }}>
                    Mode
                  </span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  value={this.state.data.pickup.unitType}
                  autoComplete="none"
                  onChange={(e) =>
                    this.handleChangeInput("pickup", "unitType", e)
                  }
                  style={{ marginLeft: "3%" }}
                  filterOption={true}
                  options={[
                    { value: "EMS" },
                    { value: "Hospital" },
                    { value: "911" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is unit type?">
                  <span className="helper" style={{ marginLeft: "4%" }}>
                    Unit Type
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="form--unit">
            <h2>
              Destination<span>*</span>
            </h2>

            <div className="h-flex">
              <div className="v-flex inputgroup">
                <AutoComplete
                  value={this.state.data.destination.location}
                  onChange={(e) =>
                    this.handleChangeInput("destination", "location", e)
                  }
                  filterOption={true}
                  autoComplete="none"
                  options={this.state.data.locations.map((locale) => ({
                    value: locale.name,
                  })).sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is location?">
                  <span className="helper" style={{ marginLeft: "2%" }}>
                    Location
                  </span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  autoComplete="none"
                  style={{ flexGrow: "1", marginLeft: "3%" }}
                  value={this.state.data.destination.mode}
                  onChange={(e) =>
                    this.handleChangeInput("destination", "mode", e)
                  }
                  filterOption={true}
                  options={[
                    { value: "Lights On" },
                    { value: "Sirens On" },
                    { value: "Lights Off" },
                    { value: "Sirens Off" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is mode?">
                  <span className="helper" style={{ marginLeft: "4%" }}>
                    Mode
                  </span>
                </Tooltip>
              </div>
              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  autoComplete="none"
                  style={{ marginLeft: "3%" }}
                  value={this.state.data.destination.unitType}
                  onChange={(e) =>
                    this.handleChangeInput("destination", "unitType", e)
                  }
                  filterOption={true}
                  options={[
                    { value: "EMS" },
                    { value: "Hospital" },
                    { value: "911" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <Tooltip placement="bottomLeft" title="What is unit type?">
                  <span className="helper" style={{ marginLeft: "4%" }}>
                    Unit Type
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="v-flex" style={{ marginTop: "1em" }}>
              <div className="h-flex">
                <div className="v-flex inputgroup">
                  <Input
                    className="user-ant--input"
                    value={this.state.data.destination.caregiverName}
                    onChange={(e) =>
                      this.handleChangeInput("destination", "caregiverName", e)
                    }
                  />
                  <Tooltip placement="bottomLeft" title="What is caregiver?">
                    <span className="helper" style={{ marginLeft: "1%" }}>
                      Caregiver
                    </span>
                  </Tooltip>
                </div>
                <div className="v-flex inputgroup">
                  <AutoComplete
                    className="user--in"
                    autoComplete="none"
                    style={{ marginLeft: "3%" }}
                    value={this.state.data.destination.caregiverRole}
                    onChange={(e) =>
                      this.handleChangeInput("destination", "caregiverRole", e)
                    }
                    filterOption={true}
                    options={[
                      { value: "Nurse" },
                      { value: "Doctor" },
                      { value: "EMT" },
                      { value: "EMR" },
                      { value: "AEMT" },
                      { value: "Paramedic" },
                    ].sort((a, b) => a.value.localeCompare(b.value))}
                  />
                  <Tooltip
                    placement="bottomLeft"
                    title="What is caregiver role?"
                  >
                    <span className="helper" style={{ marginLeft: "4%" }}>
                      Caregiver Role
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button className="next-button" onClick={() => this.advance()}>
          Next
        </button>

        <Modal
          title="Add a Crew Member"
          centered
          visible={this.state.modalMemberVisible}
          onOk={() => this.setModalMemberVisible(false)}
          onCancel={() => this.setModalMemberVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalMemberVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.addMembers()}
            >
              Add
            </Button>,
          ]}
        >
          <div className="input-modal">
            <div className="h-flex">
              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input user--in"
                  value={this.state.newCrewMember.name}
                  onChange={(e) => this.handleChangeNewCrewMember("name", e)}
                />
                <span className="helper" style={{ marginLeft: "2%" }}>
                  Name
                </span>
              </div>

              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input unin user--in"
                  style={{ flexGrow: "1" }}
                  value={this.state.newCrewMember.id}
                  onChange={(e) => this.handleChangeNewCrewMember("id", e)}
                  maxLength={25}
                />
                <span className="helper" style={{ marginLeft: "7%" }}>
                  ID
                </span>
              </div>
            </div>

            <div className="h-flex" style={{ marginTop: "3%" }}>
              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user--in"
                  autoComplete="none"
                  value={this.state.newCrewMember.level}
                  filterOption={true}
                  onChange={(e) => this.handleChangeNewCrewMember("level", e)}
                  options={[
                    { value: "EMT" },
                    { value: "EMR" },
                    { value: "AEMT" },
                    { value: "Paramedic" },
                  ].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <span className="helper" style={{ marginLeft: "2%" }}>
                  Level
                </span>
              </div>

              <div className="v-flex inputgroup">
                <AutoComplete
                  className="user-ant--input unin user--in"
                  autoComplete="none"
                  value={this.state.newCrewMember.role}
                  filterOption={true}
                  onChange={(e) => this.handleChangeNewCrewMember("role", e)}
                  options={[{ value: "Caregiver" }, { value: "Driver" }].sort((a, b) => a.value.localeCompare(b.value))}
                />
                <span className="helper" style={{ marginLeft: "7%" }}>
                  Role
                </span>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          title="Add a Location"
          centered
          visible={this.state.modalLocationVisible}
          onOk={() => this.setModalLocationVisible(false)}
          onCancel={() => this.setModalLocationVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalLocationVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.addLocations()}
            >
              Add
            </Button>,
          ]}
        >
          <div className="input-modal">
            <div className="form--unit">
              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input user--in"
                  value={this.state.newLocation.name}
                  onChange={(e) => this.handleChangeNewLocation("name", e)}
                />
                <span className="helper" style={{ marginLeft: "2%" }}>
                  Name
                </span>
              </div>

              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input user--in"
                  style={{ marginTop: "3%" }}
                  value={this.state.newLocation.address}
                  onChange={(e) => this.handleChangeNewLocation("address", e)}
                />
                <span className="helper" style={{ marginLeft: "2%" }}>
                  Address
                </span>
              </div>
            </div>

            <div className="h-flex" style={{ marginTop: "-3%" }}>
              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input user--in"
                  style={{ flexGrow: "1" }}
                  value={this.state.newLocation.lat}
                  onChange={(e) => this.handleChangeNewLocation("lat", e)}
                  maxLength={25}
                />
                <span className="helper" style={{ marginLeft: "3%" }}>
                  Latitude
                </span>
              </div>

              <div className="v-flex inputgroup">
                <Input
                  className="user-ant--input user--in"
                  style={{ flexGrow: "1", marginLeft: "3%" }}
                  value={this.state.newLocation.long}
                  onChange={(e) => this.handleChangeNewLocation("long", e)}
                  maxLength={25}
                />
                <span className="helper" style={{ marginLeft: "7%" }}>
                  Longitude
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default NewDispatch;
