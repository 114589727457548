import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import {
  Input,
  Button,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Modal,
  Tooltip,
} from "antd";
const { TextArea } = Input;

class NewAssessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "assessment",
        complaints: [],
        assessments: [],
        vitals: {
          oxygenSaturation: "",
          bloodPressureSystolic: "",
          bloodPressureDiastolic: "",
          breathingRate: "",
          breathingRhythm: null,
          breathingDepth: null,
          breathingNoise: "",
          breathingNarrative: "",
          circulationRate: "",
          circulationTemperature: "",
          circulationRhythm: null,
          circulationTemperatureUnit: null,
          circulationStrength: null,
          circulationColor: "",
          eyesEqual: null,
          eyesRound: null,
          eyesReactive: null,
          narrative: "",
        },
      },
      newComplaint: {
        type: undefined,
        complaintType: undefined,
      },
    };
  }

  componentDidMount() {}

  handleChangeNewComplaint(type, event) {
    let dt = "";

    if (!["type", "complaintType"].includes(type)) {
      dt = event.target.value;
    } else {
      dt = event;
    }
    const copyState = { ...this.state.newComplaint, [type]: dt };
    this.setState({ newComplaint: copyState });
  }

  async addComplaint() {
    let uid =
      new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

    let newComplaint = [...this.state.data.complaints, this.state.newComplaint];
    const copyData = { ...this.state.data, complaints: newComplaint };
    const copyComplaint = {
      id: uid,
      type: undefined,
      complaintType: undefined,
    };

    this.setState({
      newComplaint: copyComplaint,
      data: copyData,
    });
  }

  async removeComplaint(complaintID) {
    let newComplaint = this.state.data.complaints.filter(function (complaint) {
      return complaint.id != complaintID;
    });
    const copyData = { ...this.state.data, complaints: newComplaint };
    this.setState({
      data: copyData,
    });
  }

  async addAssessment() {
    let uid =
      new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

    const copyAssessment = {
      id: uid,
    };

    let newAssessment = [...this.state.data.assessments, copyAssessment];
    const copyData = { ...this.state.data, assessments: newAssessment };

    this.setState({
      data: copyData,
    });
  }

  async removeAssessment(assessmentID) {
    let newAssessment = this.state.data.assessments.filter(function (
      assessment
    ) {
      return assessment.id != assessmentID;
    });
    const copyData = { ...this.state.data, assessments: newAssessment };
    this.setState({
      data: copyData,
    });
  }

  handleChangeVitals(section, event) {
    let dt = "";
    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }
    const copyVitals = { ...this.state.data.vitals, [section]: dt };
    const newData = { ...this.state.data, vitals: copyVitals };
    this.setState({ data: newData });
  }

  handleChangeInput(id, section, type, event) {
    let dt = "";

    if (typeof event.target == "undefined") {
      dt = event;
    } else {
      dt = event.target.value;
    }

    let obj = this.state.data[section].filter(function (obj) {
      return obj.id == id;
    })[0];
    obj[type] = dt;

    const updatedObj = this.state.data[section].map((objSel) => {
      return objSel.id == obj.id ? obj : objSel;
    });

    const newData = { ...this.state.data, [section]: updatedObj };
    this.setState({ data: newData });
  }

  advance() {
    this.props.updateTab("5");
    console.log(this.state.data);
  }

  render() {
    return (
      <div className="user-hometab--content">
        <h1>Assessment</h1>
        <p>What did you do..?</p>
        <div className="form--input-container">
          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Complaint<span>*</span>
              </h2>

              <button
                className="form--addon"
                onClick={() => this.addComplaint()}
              >
                + New Complaint
              </button>
            </div>

            {this.state.data.complaints.map((complaint, key) => (
              <div
                className="form--unit form--patient-record"
                key={key + "-select-complaint"}
              >
                <div className="h-flex">
                  <div
                    className="v-flex inputgroup"
                    style={{ width: "50%", flexGrow: "1" }}
                  >
                    <AutoComplete
                      className="user-ant--input user--in"
                      value={complaint.type}
                      autoComplete="none"
                      filterOption={true}
                      options={[{ value: "Chief" }, { value: "Secondary" }].sort((a, b) => a.value.localeCompare(b.value))}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "type",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Type?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Type
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ width: "50%", flexGrow: "1", marginLeft: "3%" }}
                  >
                    <Select
                      className="user-ant--input user--in"
                      value={complaint.complaintType}
                      options={[{ value: "Trauma" }, { value: "Medical" }].sort((a, b) => a.value.localeCompare(b.value))}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "complaintType",
                          e
                        )
                      }
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Complaint Type?"
                    >
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Complaint Type
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex" style={{ marginTop: "1.5%" }}>
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{ flexGrow: "1" }}
                      value={complaint.location}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "location",
                          e
                        )
                      }
                      options={[
                        { value: "Chest" },
                        { value: "Back" },
                        { value: "Arm" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Location?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Location
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      value={complaint.organSystem}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "organSystem",
                          e
                        )
                      }
                      options={[
                        { value: "Neuro" },
                        { value: "Cardiovascular" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is organ system?"
                    >
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Organ System
                      </span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      style={{ flexGrow: "1" }}
                      value={complaint.onset}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "onset",
                          e
                        )
                      }
                      options={[
                        { value: "1 week ago" },
                        { value: "Two weeks ago" },
                        { value: "Three weeks ago" },
                        { value: "Four weeks ago" },
                        { value: "Five weeks ago" },
                        { value: "1 month ago" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is onset?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Onset
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex" style={{ marginTop: "1.5%" }}>
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Input
                      className="user-ant--input user--in"
                      value={complaint.provocation}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "provocation",
                          e
                        )
                      }
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is provocation?"
                    >
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Provocation
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    <Input
                      className="user-ant--input user--in"
                      value={complaint.quality}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "quality",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Quality?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Quality
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginRight: "3%" }}
                  >
                    <Input
                      className="user-ant--input user--in"
                      value={complaint.radiation}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "radiation",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Radiation?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Radiation
                      </span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      options={[
                        { value: "1" },
                        { value: "2" },
                        { value: "3" },
                        { value: "4" },
                        { value: "5" },
                        { value: "6" },
                        { value: "7" },
                        { value: "8" },
                        { value: "9" },
                        { value: "10" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                      value={complaint.severity}
                      onChange={(e) =>
                        this.handleChangeInput(
                          complaint.id,
                          "complaints",
                          "severity",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is severity?">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Severity
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginTop: "1.5%" }}
                >
                  <TextArea
                    className="textarea"
                    rows={4}
                    value={complaint.timing}
                    onChange={(e) =>
                      this.handleChangeInput(
                        complaint.id,
                        "complaints",
                        "timing",
                        e
                      )
                    }
                    maxLength={700}
                  />
                  <Tooltip placement="bottomLeft" title="What is Timing?">
                    <span className="helper" style={{ marginLeft: "2%" }}>
                      Timing
                    </span>
                  </Tooltip>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginTop: "1.5%" }}
                >
                  <TextArea
                    className="textarea"
                    style={{ marginTop: "1.5%" }}
                    rows={4}
                    value={complaint.narrative}
                    onChange={(e) =>
                      this.handleChangeInput(
                        complaint.id,
                        "complaints",
                        "narrative",
                        e
                      )
                    }
                    maxLength={700}
                  />
                  <Tooltip placement="bottomLeft" title="What is Narrative?">
                    <span className="helper" style={{ marginLeft: "2%" }}>
                      Narrative
                    </span>
                  </Tooltip>
                </div>

                <div className="h-flex">
                  <button
                    className="form--addon delete-form"
                    style={{ marginBottom: "1em" }}
                    onClick={() => this.removeComplaint(complaint.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="form--unit">
            <div className="h-flex">
              <h2>
                Assessments<span>*</span>
              </h2>

              <button
                className="form--addon"
                onClick={() => this.addAssessment()}
              >
                + New Assessment
              </button>
            </div>

            {this.state.data.assessments.map((assessment, key) => (
              <div
                className="form--unit form--patient-record"
                key={key + "-select-assessment"}
              >
                <h3>Responsiveness Test (AVPU)</h3>

                <div className="h-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user-ant--input user--in"
                      style={{ flexGrow: "1" }}
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.alert}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "alert",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Alert">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Alert
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    <Select
                      className="user-ant--input user--in"
                      style={{
                        flexGrow: "1",
                        marginLeft: "3%",
                        marginRight: "3%",
                      }}
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.verbal}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "verbal",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Verbal">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Verbal
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginRight: "3%" }}
                  >
                    <Select
                      className="user-ant--input user--in"
                      style={{ flexGrow: "1", marginRight: "3%" }}
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.painful}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "painful",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Painful">
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Painful
                      </span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user-ant--input user--in"
                      style={{ flexGrow: "1" }}
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.unresponsive}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "unresponsive",
                          e
                        )
                      }
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Unresponsive"
                    >
                      <span className="helper" style={{ marginLeft: "2%" }}>
                        Unresponsive
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <h3>Airway Breathing Circulation (ABC)</h3>

                <div className="h-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user-ant--input user--in"
                      style={{ flexGrow: "1" }}
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.airway}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "airway",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Airway">
                      <span className="helper">Airway</span>
                    </Tooltip>
                  </div>
                </div>

                <h3>Oxygen Saturation</h3>
                <div className="h-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <AutoComplete
                      className="user--in"
                      autoComplete="none"
                      filterOption={true}
                      value={assessment.oxygenSaturation}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "oxygenSaturation",
                          e
                        )
                      }
                      options={[
                        { value: "Below 90%" },
                        { value: "90%" },
                        { value: "91%" },
                        { value: "92%" },
                        { value: "93%" },
                        { value: "94%" },
                        { value: "95%" },
                        { value: "96%" },
                        { value: "97%" },
                        { value: "98%" },
                        { value: "99%" },
                        { value: "100%" },
                      ].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip
                      placement="bottomLeft"
                      title="What is Oxygen Saturation"
                    >
                      <span className="helper">Oxygen Saturation</span>
                    </Tooltip>
                  </div>
                </div>

                <h3>Blood Pressure</h3>
                <div className="h-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Input
                      className="user-ant--input user--in"
                      value={assessment.bloodPressureSystolic}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "bloodPressureSystolic",
                          e
                        )
                      }
                      maxLength={10}
                    />
                    <Tooltip placement="bottomLeft" title="What is Systolic">
                      <span className="helper">Systolic</span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginLeft: "3%" }}
                  >
                    <Input
                      className="user-ant--input user--in"
                      value={assessment.bloodPressureDiastolic}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "bloodPressureDiastolic",
                          e
                        )
                      }
                      maxLength={10}
                    />
                    <Tooltip placement="bottomLeft" title="What is Diastolic">
                      <span className="helper">Diastolic</span>
                    </Tooltip>
                  </div>
                </div>

                <h3>Breathing</h3>
                <div className="h-flex">
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Input
                      className="user-ant--input user--in"
                      value={assessment.breathingRate}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "breathingRate",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Rate">
                      <span className="helper">Rate</span>
                    </Tooltip>
                  </div>
                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginLeft: "3%",
                      marginRight: "3%",
                    }}
                  >
                    <Select
                      className="user-ant--input user--in"
                      options={[{ value: "Regular" }, { value: "Irregular" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.breathingRhythm}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "breathingRhythm",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Rhythm">
                      <span className="helper">Rhythm</span>
                    </Tooltip>
                  </div>
                  <div
                    className="v-flex inputgroup"
                    style={{ flexGrow: "1", marginRight: "3%" }}
                  >
                    <Select
                      className="user-ant--input user--in"
                      options={[{ value: "Shallow" }, { value: "Deep" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.breathingDepth}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "breathingDepth",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Depth">
                      <span className="helper">Depth</span>
                    </Tooltip>
                  </div>
                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <AutoComplete
                      className="user-ant--input user--in"
                      autoComplete="none"
                      filterOption={true}
                      options={[{ value: "Snoring" }, { value: "Wheezing" }].sort((a, b) => a.value.localeCompare(b.value))}
                      value={assessment.breathingNoise}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "breathingNoise",
                          e
                        )
                      }
                    />
                    <Tooltip placement="bottomLeft" title="What is Noise">
                      <span className="helper">Noise</span>
                    </Tooltip>
                  </div>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginTop: "1.5%", marginBottom: "1.5%" }}
                >
                  <TextArea
                    className="textarea"
                    value={assessment.breathingNarrative}
                    onChange={(e) =>
                      this.handleChangeInput(
                        assessment.id,
                        "assessments",
                        "breathingNarrative",
                        e
                      )
                    }
                    rows={4}
                    maxLength={700}
                  />
                  <Tooltip placement="bottomLeft" title="What is Narrative">
                    <span className="helper">Narrative</span>
                  </Tooltip>
                </div>

                <h3>Circulation</h3>

                <div className="v-flex">
                  <div className="h-flex">
                    <div
                      className="v-flex inputgroup"
                      style={{ flexGrow: "1" }}
                    >
                      <Input
                        className="user-ant--input user--in"
                        value={assessment.circulationRate}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationRate",
                            e
                          )
                        }
                      />
                      <Tooltip placement="bottomLeft" title="What is Rate">
                        <span className="helper">Rate</span>
                      </Tooltip>
                    </div>
                    <div
                      className="v-flex inputgroup"
                      style={{
                        flexGrow: "1",
                        marginLeft: "3%",
                        marginRight: "3%",
                      }}
                    >
                      <Select
                        className="user-ant--input user--in"
                        options={[{ value: "Regular" }, { value: "Irregular" }].sort((a, b) => a.value.localeCompare(b.value))}
                        value={assessment.circulationRhythm}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationRhythm",
                            e
                          )
                        }
                      />
                      <Tooltip placement="bottomLeft" title="What is Rhythm">
                        <span className="helper">Rhythm</span>
                      </Tooltip>
                    </div>
                    <div
                      className="v-flex inputgroup"
                      style={{ flexGrow: "1" }}
                    >
                      <Select
                        className="user-ant--input user--in"
                        options={[{ value: "Strong" }, { value: "Weak" }].sort((a, b) => a.value.localeCompare(b.value))}
                        value={assessment.circulationStrength}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationStrength",
                            e
                          )
                        }
                      />
                      <Tooltip placement="bottomLeft" title="What is Strength">
                        <span className="helper">Strength</span>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="h-flex">
                    <div
                      className="v-flex inputgroup"
                      style={{ flexGrow: "1", marginRight: "3%" }}
                    >
                      <Input
                        className="user-ant--input user--in"
                        value={assessment.circulationTemperature}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationTemperature",
                            e
                          )
                        }
                      />
                      <Tooltip placement="bottomLeft" title="What is Alert">
                        <span className="helper">Alert</span>
                      </Tooltip>
                    </div>

                    <div
                      className="v-flex inputgroup"
                      style={{ flexGrow: "1", marginRight: "3%" }}
                    >
                      <Select
                        className="user-ant--input user--in"
                        options={[{ value: "Fh" }, { value: "Celcius" }].sort((a, b) => a.value.localeCompare(b.value))}
                        value={assessment.circulationTemperatureUnit}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationTemperatureUnit",
                            e
                          )
                        }
                      />
                      <Tooltip
                        placement="bottomLeft"
                        title="What is Temperature Unit"
                      >
                        <span className="helper">Temperature Unit</span>
                      </Tooltip>
                    </div>

                    <div
                      className="v-flex inputgroup"
                      style={{ flexGrow: "1", marginRight: "3%" }}
                    >
                      <AutoComplete
                        className="user-ant--input user--in"
                        filterOption={true}
                        autoComplete="none"
                        style={{ flexGrow: "1" }}
                        options={[
                          { value: "Blue" },
                          { value: "Pink" },
                          { value: "Flesh" },
                          { value: "Yellow" },
                        ].sort((a, b) => a.value.localeCompare(b.value))}
                        value={assessment.circulationColor}
                        onChange={(e) =>
                          this.handleChangeInput(
                            assessment.id,
                            "assessments",
                            "circulationColor",
                            e
                          )
                        }
                      />
                      <Tooltip
                        placement="bottomLeft"
                        title="What is Skin Color"
                      >
                        <span className="helper">Skin Color</span>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div
                  className="v-flex inputgroup"
                  style={{ marginTop: "1.5%", marginBottom: "1.5%" }}
                >
                  <TextArea
                    className="textarea"
                    rows={4}
                    value={assessment.circulationNarrative}
                    onChange={(e) =>
                      this.handleChangeInput(
                        assessment.id,
                        "assessments",
                        "circulationNarrative",
                        e
                      )
                    }
                    maxLength={700}
                  />
                  <Tooltip placement="bottomLeft" title="What is Narrative">
                    <span className="helper">Narrative</span>
                  </Tooltip>
                </div>

                <div className="v-flex">
                  <h3>Eyes Equal Round Reactive</h3>

                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user--in"
                      value={assessment.eyesEqual}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "eyesEqual",
                          e
                        )
                      }
                      allowClear
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is equal">
                      <span className="helper">
                        Equal, do both eyes dilate?
                      </span>
                    </Tooltip>
                  </div>

                  <div
                    className="v-flex inputgroup"
                    style={{
                      flexGrow: "1",
                      marginTop: "1.5%",
                      marginBottom: "1.5%",
                    }}
                  >
                    <Select
                      className="user--in"
                      value={assessment.eyesRound}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "eyesRound",
                          e
                        )
                      }
                      allowClear
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Round">
                      <span className="helper">
                        Round, are both pupils round?
                      </span>
                    </Tooltip>
                  </div>

                  <div className="v-flex inputgroup" style={{ flexGrow: "1" }}>
                    <Select
                      className="user--in"
                      value={assessment.eyesReactive}
                      onChange={(e) =>
                        this.handleChangeInput(
                          assessment.id,
                          "assessments",
                          "eyesReactive",
                          e
                        )
                      }
                      allowClear
                      options={[{ value: "Yes" }, { value: "No" }].sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <Tooltip placement="bottomLeft" title="What is Reactive">
                      <span className="helper">
                        Reactive, are their eyes responsive to light?
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="h-flex">
                  <button
                    className="form--addon delete-form"
                    style={{ marginBottom: "1em" }}
                    onClick={() => this.removeAssessment(assessment.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>

          {this.state.data.complaints.length > 0 ||
          this.state.data.assessments.length > 0 ? (
            <div className="form--unit">
              <h2>Narrative</h2>
              <TextArea
                className="textarea"
                value={this.state.data.vitals.narrative}
                onChange={(e) => this.handleChangeVitals("narrative", e)}
                rows={4}
                placeholder="Optional..."
                maxLength={700}
              />
            </div>
          ) : (
            " "
          )}
        </div>

        {this.state.data.complaints.length > 0 ||
        this.state.data.assessments.length > 0 ? (
          <button className="next-button" onClick={() => this.advance()}>
            Next
          </button>
        ) : (
          " "
        )}
      </div>
    );
  }
}

export default NewAssessment;
