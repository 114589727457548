import React from "react";
import { Link } from "react-router-dom";
import {NodeIndexOutlined} from "@ant-design/icons";
import logo from "../img/pp.png";

class EmptyRecords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        
    };
  }

  componentDidMount() {

  }

  render() {
    return <div className="empty-container">
        <img src={this.props.image} />
        <h2>{this.props.title}</h2>
        <p>{this.props.description}</p>
    </div>;
  }
}

export default EmptyRecords;
