import React from "react";
import { Link } from "react-router-dom";
import {NodeIndexOutlined} from "@ant-design/icons";
import logo from "../img/pp.png";

class Appbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        activeTab: 0,
    };
  }

  componentDidMount() {
    let path = window.location.pathname

    if(path == "/"){
        this.setState({ activeTab: -1 })
    }else if(path == "/bundles"){
        this.setState({ activeTab: 0 })
    }else if(path.includes('/trends')){
        this.setState({ activeTab: 1 })
    }else{
        this.setState({ activeTab: 0 })
    }
  }

  render() {
    return <div className="appbar">
        <Link to="/" className="appbar--logo"><span><img src={logo} /></span></Link>
        <div className="appbar--links">
            <Link to="/about" className={"appbar--links-link" + (this.state.activeTab == 1 ? ' active' : '')}>
                About</Link>
            <Link to="/security" className={"appbar--links-link" + (this.state.activeTab == 1 ? ' active' : '')}>
                Security</Link>
            <Link to="/" className={"appbar--links-link login-button"} onClick={this.props.login}>
                Login</Link>
        </div>
    </div>;
  }
}

export default Appbar;
