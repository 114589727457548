import React from "react";
import { Link } from "react-router-dom";
import { BankOutlined, BellFilled } from "@ant-design/icons";
import mike from "../img/mike.jpg";
import {
  Menu,
  List,
  Avatar,
  Select,
  Modal,
  Button,
  Input,
  InputNumber,
} from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  ToolOutlined,
  ToolFilled,
  NumberOutlined,
} from "@ant-design/icons";
import {
  createSupply,
  getSupply,
  getSupplies,
  updateSupply,
  updateSupplyStatus,
  deleteSupply,
} from "../provider/models/SupplyModel.js";

import { getOrg } from "../provider/models/OrgModel.js";

import EmptyRecords from "../components/EmptyRecords.js";
import doctorStand from "../img/doctorStand.svg";
import HomeAppbar from "../components/HomeAppbar.js";

const { SubMenu } = Menu;
const { Option } = Select;

class Supply extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "inventory",
      modalToolVisible: false,
      modalSelectedTeam: null,
      organizations: [],
      toolName: "",
      quantity: 1,
      supplies: [],
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleChangeOrgTeam = this.handleChangeOrgTeam.bind(this);
  }

  componentDidMount() {
    this.loadOrgs();
    this.loadSupplies();
  }

  async loadSupplies() {
    let currentState = this;
    let yourSupply = [];

    await getSupplies().then(async (supplyRef) => {
      for (let i = 0; i < supplyRef.length; i++) {
        let supply = supplyRef[i];

        await getOrg(supply.orgID).then(async (orgRef) => {
          supply.orgID = orgRef;
          yourSupply.push(supply);
        });
      }
      currentState.setState({
        supplies: yourSupply,
      });
    });
  }

  async loadOrgs() {
    let currentState = this;
    let yourOrgs = [];
    let oldOrgs = this.props.user.orgs;
    for (let i = 0; i < oldOrgs.length; i++) {
      if (oldOrgs.length > 0) {
        let org = await getOrg(oldOrgs[i]);
        if (org.state == "published") {
          yourOrgs.push(org);
          currentState.setState({ organizations: yourOrgs });
        }
      }
    }
  }

  setModalToolVisible(visible) {
    this.setState({
      modalToolVisible: visible,
      modalSelectedTeam: null,
      toolName: "",
      quantity: 1,
    });
  }

  onChangeQuantity = (quan) => {
    if (quan > 0) {
      this.setState({ quantity: quan });
    }
  };

  onChangeToolName = (e) => {
    this.setState({ toolName: e.target.value });
  };

  handleTabChange(currentTab) {
    this.setState({
      current: currentTab,
    });
  }

  async createNewSupply() {
    let currentState = this;
    if (
      currentState.state.modalSelectedTeam &&
      currentState.state.modalSelectedTeam.admins.includes(
        currentState.props.user.userID
      )
    ) {
      if (
        currentState.state.toolName.length > 2 &&
        currentState.state.quantity > 0
      ) {
        for (let step = 0; step < currentState.state.quantity - 1; step++) {
          let tempID = new Date().getTime().toString(36);

          let newSupply = {
            id: tempID,
            name: currentState.state.toolName,
            orgID: currentState.state.modalSelectedTeam.id,
            inUse: false,
          };
          let stateSupply = [newSupply].concat(currentState.state.supplies);

          await createSupply({
            name: currentState.state.toolName,
            orgID: currentState.state.modalSelectedTeam.id,
            inUse: false,
          }).then(async (supplyRef) => {
            let supplyID = supplyRef.id;
            newSupply.id = supplyID;

            currentState.setState({
              supplies: stateSupply,
            });
          });
        }
      }
    }
    this.setModalToolVisible(false);
  }

  handleChangeOrgTeam(teamID) {
    let selectedOrg = this.state.organizations.filter(function (item) {
      return item.id == teamID;
    });

    let shortOrganization = selectedOrg[0].members.map(
      (userOrg) => userOrg.userID
    );

    if (selectedOrg.length > 0) {
      this.setState({
        modalSelectedTeam: selectedOrg[0],
      });
    }
  }

  render() {
    return (
      <div className="main-container">
        <div className="main-header">
          <HomeAppbar user={this.props.user} logout={this.props.logout} />

          <h1>Supply</h1>

          <Menu
            onClick={this.handleTabChange}
            selectedKeys={[this.state.current]}
            mode="horizontal"
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              color: "gray",
            }}
          >
            <Menu.Item
              key="inventory"
              onClick={() => this.handleTabChange("inventory")}
            >
              Inventory
            </Menu.Item>
            <Menu.Item key="use" onClick={() => this.handleTabChange("use")}>
              In Use
            </Menu.Item>

            <Menu.Item
              key="new"
              icon={<ToolOutlined />}
              style={{ marginLeft: "auto" }}
              onClick={() => this.setModalToolVisible(true)}
            >
              Add Tools
            </Menu.Item>
          </Menu>
        </div>

        <div
          className={
            this.state.supplies.length > 0
              ? "table-container"
              : "empty-container"
          }
        >
          {this.state.supplies.length > 0 ? (
            <div className="table-list--container">
              <List
                itemLayout="horizontal"
                dataSource={this.state.supplies}
                renderItem={(item) => (
                  <List.Item className="all-teams--item">
                    <List.Item.Meta
                      avatar={
                        <span className="toolAvatar">
                          <span className="toolAvatarIcon">
                            <ToolOutlined />
                          </span>
                        </span>
                      }
                      title={
                        <div className="v-flex orgName-item">
                          <span className="orgName--desc">{item.name}</span>
                        </div>
                      }
                      description={item.orgID.name}
                    />

                    <div className="v-flex orgName-item-mid">
                      <span className="orgName--supply">
                        {"ID: " + item.id}
                      </span>
                    </div>

                    <Select
                      className="member-tags"
                      style={{ width: 130 }}
                      value={item.inUse}
                    >
                      <Option value={true}>In Use</Option>
                      <Option value={false}>Not In Use</Option>
                    </Select>
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <EmptyRecords
              image={doctorStand}
              title="Oh no, you need more supply..."
              description="Looks like your supply is empty"
            />
          )}
        </div>

        <Modal
          title="Add Supply"
          centered
          visible={this.state.modalToolVisible}
          onOk={() => this.setModalToolVisible(false)}
          onCancel={() => this.setModalToolVisible(false)}
          footer={[
            <Button
              key="cancel"
              type="text"
              onClick={() => this.setModalToolVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="primaryButton"
              onClick={() => this.createNewSupply()}
            >
              Add
            </Button>,
          ]}
        >
          <div className="input-modal">
            <div className="input-modal--label">Tool Name</div>
            <Input
              onChange={this.onChangeToolName}
              value={this.state.toolName}
              size="large"
              placeholder=""
              className="input-modal--input"
              prefix={<ToolOutlined />}
            />
          </div>

          <div className="input-modal member">
            <div className="input-modal--label">Select Organization</div>
            <Select
              className="input-modal--input"
              style={{ width: "100%" }}
              value={
                this.state.modalSelectedTeam
                  ? this.state.modalSelectedTeam.name
                  : ""
              }
              onChange={this.handleChangeOrgTeam}
            >
              {this.state.organizations.map((team, key) => (
                <Option key={key + "-select-users"} value={team.id}>
                  {team.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="input-modal">
            <div className="input-modal--label">Quantity</div>
            <InputNumber
              size="large"
              value={this.state.quantity}
              onChange={this.onChangeQuantity}
              style={{ width: "100%" }}
              placeholder=""
              className="input-modal--input"
              prefix={<NumberOutlined />}
            />
          </div>

          <div className="input-modal">
            <div className="input-modal--label">Owner</div>
            <Input
              size="large"
              value={this.props.user.name}
              placeholder=""
              className="input-modal--input"
              prefix={<UserOutlined />}
              disabled
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Supply;
