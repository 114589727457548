import React from "react";
import { Link } from "react-router-dom";
import UserAppbar from "../../components/UserAppbar.js";
import Footer from "../../components/Footer.js";
import EmptyRecords from "../../components/EmptyRecords.js";
import contract from "../../img/contract.svg";
import NewDispatch from "./NewDispatch.js";
import NewScene from "./NewScene.js";
import NewPatient from "./NewPatient.js";
import NewAssessment from "./NewAssessment.js";
import NewTreatment from "./NewTreatment.js";
import NewSignatures from "./NewSignatures.js";

import { Tabs, Input } from 'antd';

import {
  ShareAltOutlined,
  GlobalOutlined,
  SolutionOutlined,
  AndroidOutlined,
  AppleOutlined,
  PhoneOutlined,
  AimOutlined,
  PieChartOutlined,
  ExperimentOutlined,
  EnvironmentOutlined,
  WomanOutlined,
  ReadOutlined,
  HighlightOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { Navigate } from 'react-router-dom'

const { TabPane } = Tabs;

class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };

  }

  componentDidMount() {


  }

  updateTab(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  render() {
      
    return (
      this.props.user ? (


      <div className="dash-main-container">
        <UserAppbar logout={this.props.logout} user={this.props.user}/>
        <div className="user-main--content">
            <div className="home-c">
                <div className="home-c--content">
                    <Tabs activeKey={this.state.activeTab} defaultActiveKey="1"  tabPosition="right" centered>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("1")}>
                            Dispatch
                            </span>
                        }
                        key="1"
                        >
                        <NewDispatch updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("2")}>
                            Scene
                            </span>
                        }
                        key="2"
                        >
                        <NewScene updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("3")}>
                            Patient
                            </span>
                        }
                        key="3"
                        >
                        <NewPatient updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("4")}>
                            Assessment
                            </span>
                        }
                        key="4"
                        >
                        <NewAssessment updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("5")}>
                            Treatment
                            </span>
                        }
                        key="5"
                        >
                        <NewTreatment updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                        <TabPane
                        tab={
                            <span className="menu-item" onClick={() => this.updateTab("6")}>
                            Signatures
                            </span>
                        }
                        key="6"
                        >
                        <NewSignatures updateTab={this.updateTab.bind(this)} />
                        </TabPane>
                    </Tabs>

                </div>

            </div>
            
            
        </div>
      </div>


      ) : (
        <Navigate to={{ pathname: '/', state: { from: "/new" } }} />
      )

    );
  }
}

export default New;
