import { doc, getDoc, setDoc, getFirestore, collection, getDocs, updateDoc, deleteDoc, addDoc, query, where, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from "../firestore.js";

const db = firebase.firestore();

export const createSupply = async (supply) => {
    const supplyRef = await addDoc(collection(db, "supply"), supply);
    return supplyRef
}

export const getSupply = async (id) => {
    const supplySnapshot = await getDoc(doc(db, 'supply', id));
    if (supplySnapshot.exists()) {
        let supplyData = supplySnapshot.data()
        supplyData.id = id
        return supplyData;
    } else {
        return null
    }
};

export const getSupplies = async () => {
    const suppliesSnapshot = await getDocs(collection(db, "supply"));
    let supplyDocs = suppliesSnapshot.docs

    let supplyList = []

    for (let i = 0; i < supplyDocs.length; i++) {
        let doc = supplyDocs[i].data()
        doc.id = supplyDocs[i].id
        supplyList.push(doc)
    }
    
    return supplyList;
};

export const updateSupply = async (supply) => {
    const supplyRef = doc(db, "supply", supply.id);
    await updateDoc(supplyRef, supply);
};


export const updateSupplyStatus = async (supply) => {
    const supplyRef = doc(db, "supply", supply.id);
    await updateDoc(supplyRef, {
        state: supply.state
    });
};

export const deleteSupply = async (supply) => {
    const orgRef = doc(db, "supply", supply.id);
    await deleteDoc(orgRef);
};
