import React from "react";
import { Link } from "react-router-dom";
import {NodeIndexOutlined} from "@ant-design/icons";
import logo from "../img/pp.png";
import defaultImage from "../img/defaultAvatar.jpeg";
import { Menu, Dropdown } from 'antd';
import { DownOutlined, FolderAddOutlined } from '@ant-design/icons';


class UserAppbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        activeTab: 0,
    };
  }

  componentDidMount() {

  }

  generateNewId(){
    let uid = Date.now()
    return uid;
  }

  render() {

    const menu = (
      <Menu>
        <Menu.Item key="profileImg">
          <a href="#">
            Change Profile Image
          </a>
        </Menu.Item>
        <Menu.Item key="logout" onClick={this.props.logout} >Log Out</Menu.Item>
      </Menu>
    );


    return <div className="appbar-user">
        <Link to="/" className="user-appbar--logo"><span><img src={logo} /></span></Link>

        <div className="userappbar--links">
            <Link to={"/pcr/" + this.generateNewId()} className={"appbar--links-link pcr-button"}>
              New Record</Link> 
            <Dropdown overlay={menu} className="appbar--links-link profile-link" >
              <img src={this.props.user && this.props.user.photo ? this.props.user.photo : defaultImage} onClick={e => e.preventDefault()} />
            </Dropdown>
        </div>
    </div>;
  }
}

export default UserAppbar;
